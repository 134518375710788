import {
  Layout,
  EventsTable,
  FirstSection,
  ContentImage,
  SecondarySection,
} from "../../shared/ui";
import style from "./prices.module.scss";

const IMAGE_BASE_URL = "/images/pages/prices";

const IMG = {
  mobile: {
    retina: `${IMAGE_BASE_URL}/prices.jpg`,
    normal: `${IMAGE_BASE_URL}/prices.jpg`,
    width: "303",
    height: "210",
  },
  tablet: {
    retina: `${IMAGE_BASE_URL}/prices.jpg`,
    normal: `${IMAGE_BASE_URL}/prices.jpg`,
    width: "377",
    height: "210",
  },
  desktop: {
    retina: `${IMAGE_BASE_URL}/prices.jpg`,
    normal: `${IMAGE_BASE_URL}/prices.jpg`,
    width: "661",
    height: "324",
  },
  desktopXl: {
    retina: `${IMAGE_BASE_URL}/prices.jpg`,
    normal: `${IMAGE_BASE_URL}/prices.jpg`,
    width: "626",
    height: "324",
  },
  alt: "Команда профессионалов",
};

export const EVENT_LIST_1 = [
  {
    title:
      "Замена или монтаж трубопровода ХВС в квартире после отсекающей запорной арматуры и подведение к одному прибору",
    unit: "Замена или монтаж трубопровода к 1 прибору",
    price: "3303,00",
  },
  {
    title:
      "Замена или монтаж трубопровода ГВС в квартире после отсекающей запорной арматуры и подведение к одному прибору",
    unit: "Замена или монтаж трубопровода к 1 прибору",
    price: "3303,00",
  },
  {
    title:
      "Замена или монтаж канализации в квартире после стояка и подведение к одному прибору",
    unit: "Замена или монтаж канализации к 1 прибору",
    price: "1450,00",
  },
];

const X2 = [
  {
    title:
      'Замена или установка унитаза и смывного бочка типа "Компакт" с подключением к коммуникациям',
    unit: "1 прибор",
    price: "5030,00",
  },
  {
    title:
      'Замена или установка унитаза и смывного бочка типа "Компакт" с подключением к коммуникациям, с дополнительными условиями установки',
    unit: "1 прибор",
    price: "6357,00",
  },
  {
    title: "Регулировка арматуры смывного бочка без ремонта",
    unit: "1 арматура",
    price: "600,00",
  },
  {
    title: "Частичный ремонт арматуры смывного бочка",
    unit: "1 компонент арматуры",
    price: "977,00",
  },
  {
    title: "Полная замена арматуры смывного бочка",
    unit: "1 арматура",
    price: "2890,00",
  },
  {
    title: "Разворот унитаза (без доработки коммуникаций)",
    unit: "1 прибор",
    price: "1450,00",
  },
];

const X3 = [
  {
    title: "Смесителя типа ˝Елочка˝",
    unit: "1 прибор",
    price: "1900,00",
  },
  {
    title: "Замена смесителя с душем или установка на готовые коммуникации",
    unit: "1 прибор",
    price: "2203,00",
  },
  {
    title: "Установка раковины подвесной без смесителя",
    unit: "1 прибор",
    price: "2135,00",
  },
  {
    title: "Установка раковины подвесной со смесителем",
    unit: "1 прибор",
    price: "2320,00",
  },
  {
    title: "Установка раковины на шкафчике без смесителя",
    unit: "1 прибор",
    price: "3011,00",
  },
  {
    title: "Установка раковины на шкафчике со смесителем",
    unit: "1 прибор",
    price: "4042,00",
  },
  {
    title: "Ванны",
    unit: "1 прибор",
    price: "3930,00",
  },
];

const X4 = [
  {
    title: "При смене прокладок",
    unit: "1 кран",
    price: "500,00",
  },
  {
    title: "При набивке сальника",
    unit: "1 кран",
    price: "500,00",
  },
];

const X5 = [
  {
    title: "Смеситель с душем",
    unit: "1 смеситель",
    price: "500,00",
  },
  {
    title: "Смеситель без душа",
    unit: "1 смеситель",
    price: "500,00",
  },
];

const X6 = [
  {
    title: "Смеситель с душем",
    unit: "1 смеситель",
    price: "600,00",
  },
  {
    title: "Смеситель без душа",
    unit: "1 смеситель",
    price: "500,00",
  },
  {
    title: "Устранение течи сальника излива",
    unit: "1 сальник",
    price: "500,00",
  },
];

const X7 = [
  {
    title: "К смывному бачку",
    unit: "1 подводка",
    price: "600,00",
  },
  {
    title: "К мойке",
    unit: "1 подводка",
    price: "600,00",
  },
];

const X8 = [
  {
    title: "Душ на гибком шланге",
    unit: "1 прокладка",
    price: "300,00",
  },
  {
    title: "Душ на душевой трубке",
    unit: "1 прокладка",
    price: "300,00",
  },
  {
    title: "Смена трубки гибкого шланга душа",
    unit: "1 трубка",
    price: "700,00",
  },
];

const X9 = [
  {
    title: "Душ на гибком шланге",
    unit: "1 сетка",
    price: "200,00",
  },
  {
    title: "Душ на душевой трубке",
    unit: "1 сетка",
    price: "200,00",
  },
];

const X10 = [
  {
    title: "Место установки сифона на пластмассовых трубопроводах",
    unit: "1 сифон",
    price: "635,00",
  },
  {
    title: "Место установки сифона на чугунных трубопроводах",
    unit: "1 сифон",
    price: "830,00",
  },
];

const X11 = [
  {
    title: "Смывной бачок",
    unit: "1 кронштейн",
    price: "450,00",
  },
  {
    title: "Умывальник",
    unit: "1 кронштейн",
    price: "400,00",
  },
];

const X12 = [
  {
    title: "Чугунный сифон",
    unit: "1 сифон",
    price: "325,00",
  },
  {
    title: "Пластмассовый или латунный сифон",
    unit: "1 сифон",
    price: "260,00",
  },
];

const X13 = [
  {
    title: "В трубопроводах (за исключением мест общего пользования)",
    unit: "1 пролет между ревизиями",
    price: "600,00",
  },
  {
    title: "В санитарных приборах без снятия с места",
    unit: "1 прибор",
    price: "750,00",
  },
  {
    title: "В санитарных приборах с частичным снятием с места",
    unit: "1 прибор",
    price: "1500,00",
  },
];

const X14 = [
  {
    title:
      "Смена радиаторного блока с подключением к системе центрального отопления, без устройства перемычки",
    unit: "1 радиаторный блок",
    price: "4088,00",
  },
  {
    title:
      "Смена радиаторного блока с подключением к системе центрального отопления, с устройством перемычки",
    unit: "1 радиаторный блок",
    price: "8710,00",
  },
  {
    title: "Установка термоголовки или регулирующей арматуры на радиатор",
    unit: "1 термоголовка",
    price: "715,00",
  },
];

const X15 = [
  {
    title: "Установка стиральной машины - подвод электричества от щитка",
    unit: "1 прибор",
    price: "2730,00",
  },
  {
    title:
      "Установка стиральной машины - подключение к системе водоснабжения и водоотведения",
    unit: "1 прибор",
    price: "2730,00",
  },
  {
    title: "Установка стиральной машины (подключение на готовые коммуникации)",
    unit: "1 прибор",
    price: "2080,00",
  },
  {
    title:
      "Комплексная установка стиральной машины (подключение к системе водоснабжения, водотведения и подвод электричества от щитка)",
    unit: "1 прибор",
    price: "4420,00",
  },
];

const X16 = [
  {
    title:
      "Установка полотенцесущителя с подключением к системе горячего водоснабжения, без устройства перемычки (*в случае потребности в дополнительных работах составляется смета)",
    unit: "1 прибор",
    price: "4225,00",
  },
  {
    title:
      "Установка полотенцесущителя с подключением к системе горячего водоснабжения, с устройством перемычки (*в случае потребности в дополнительных работах составляется смета)",
    unit: "1 прибор",
    price: "8710,00",
  },
];

const X17 = [
  {
    title: "Отключение и включение стояков водоснабжения для выполнения работ",
    unit: "1 стояк",
    price: "975,00",
  },
  {
    title:
      "Отключение и включение стояков водоснабжения для выполнения работ для сторонних организаций (на 1 час)",
    unit: "1 стояк",
    price: "1625,00",
  },
  {
    title: "Замена поврежденной полиэтиленовой насадки к вентильной головке",
    unit: "1 насадка",
    price: "200,00",
  },
  {
    title: "Замена маховичка вентиля или ручки переключателя на смесителе",
    unit: "1 маховичок или 1 ручка",
    price: "300,00",
  },
  {
    title: "Устранение течи в присоединениях гибких подводок к приборам",
    unit: "1 соединение",
    price: "300,00",
  },
  {
    title: "Установка фильтра для очистки воды",
    unit: "1 фильтр",
    price: "1560,00",
  },
  {
    title: "Смена выпуска у ванны",
    unit: "1 выпуск",
    price: "845,00",
  },
  {
    title: "Смена перелива у ванны",
    unit: "1 перелив",
    price: "715,00",
  },
  {
    title: "Смена полочки соединительной к унитазу",
    unit: "1 полочка",
    price: "1040,00",
  },
  {
    title: "Мелкий ремонт смывного бачка или унитаза",
    unit: "1 бачок или 1 унитаз",
    price: "975,00",
  },
  {
    title: "Мелкий ремонт высокорасположенного смывного бачка или унитаза",
    unit: "1 бачок",
    price: "975,00",
  },
  {
    title: "Смена смывной трубы",
    unit: "1 смывная труба",
    price: "1105,00",
  },
  {
    title: "Укрепление расшатанного унитаза",
    unit: "1 унитаз",
    price: "585,00",
  },
  {
    title: "Смена душа на гибком шланге",
    unit: "1 душ",
    price: "300,00",
  },
  {
    title: "Замена шарового крана",
    unit: "1 кран",
    price: "585,00",
  },
];

const X18 = [
  {
    title: "Составление 1 акта о дефектах электропроводки в квартире",
    unit: "за 1 кв. м.",
    price: "80,00",
  },
  {
    title:
      "Экспертиза электросчетчика и автоматического выключателя на наличие сторонних подключений (оформляется акт осмотра)",
    unit: "1 экспертиза",
    price: "585,00",
  },
];

const X19 = [
  {
    title: "1 точка (комплексная работа, упрощенный расчет)",
    unit: "1 точка (прибор)",
    price: "1040,00",
  },
  {
    title:
      "Замена электропроводки от ввода в квартиру (кроме мест общего пользования в коммунальных квартирах), прокладка в кабель-канале или в борозде",
    unit: "на 1 м провода",
    price: "300,00",
  },
];

const X20 = [
  {
    title: "На стенах из кирпича",
    unit: "1 м борозды",
    price: "661,00",
  },
  {
    title: "На стенах из бетона",
    unit: "1 м борозды",
    price: "661,00",
  },
  {
    title: "На потолках",
    unit: "1 м борозды",
    price: "650,00",
  },
];

const X21 = [
  {
    title: "В штукатурке",
    unit: "1 м борозды",
    price: "650,00",
  },
];

const X22 = [
  {
    title: "В бетонных перекрытиях",
    unit: "1 отверстие",
    price: "195,00",
  },
  {
    title: "В кирпичных перекрытиях",
    unit: "1 отверстие",
    price: "130,00",
  },
  {
    title: "В дверных или деревянных оконных косяках",
    unit: "1 отверстие",
    price: "65,00",
  },
];

const X23 = [
  {
    title: "Проделывание отверстий для розеток и выключателей",
    unit: "1 отверстие",
    price: "325,00",
  },
];

const X24 = [
  {
    title:
      "Смена неисправного выключателя, переключателя или штепсельной розетки для открытой и скрытой проводки или замена на другую модель, без выполнения строительных работ.",
    unit: "1 прибор",
    price: "705,00",
  },
  {
    title: "Смена неисправного потолочного патрона",
    unit: "1 прибор",
    price: "591,00",
  },
  {
    title:
      "Смена неисправной штепсельной розетки для потолочного патрона открытой и скрытой проводки или замена на другую модель",
    unit: "1 прибор",
    price: "591,00",
  },
  {
    title: "Ремонт штепсельной розетки или выключателя",
    unit: "1 розетка или 1 выкл.",
    price: "504,00",
  },
];

const X25 = [
  {
    title: "Однополюсного на DIN-рейку",
    unit: "1 выключатель",
    price: "260,00",
  },
  {
    title: "Двухполюсного на DIN-рейку",
    unit: "1 выключатель",
    price: "325,00",
  },
  {
    title: "Трехполюсного на DIN-рейку",
    unit: "1 выключатель",
    price: "390,00",
  },
  {
    title: "Однополюсного с болтовым креплением",
    unit: "1 выключатель",
    price: "325,00",
  },
  {
    title: "Двухполюсного с болтовым креплением",
    unit: "1 выключатель",
    price: "390,00",
  },
  {
    title: "Трехполюсного с болтовым креплением",
    unit: "1 выключатель",
    price: "455,00",
  },
];

const X26 = [
  {
    title: 'Замена клеммной колодки "N"',
    unit: "1 колодка",
    price: "256,00",
  },
  {
    title: 'Установка клеммной колодки "N"',
    unit: "1 колодка",
    price: "450,00",
  },
  {
    title: 'Замена болтового зажима "орех"',
    unit: "1 зажим",
    price: "450,00",
  },
];

const X27 = [
  {
    title: "Монтаж 1 выключателя в боксе",
    unit: "1 модуль",
    price: "415,00",
  },
];

const X28 = [
  {
    title: "Монтаж электрозвонка без монтажа кнопки и без проводки",
    unit: "1 электрозвонок",
    price: "650,00",
  },
  {
    title: "Монтаж электрозвонка с кнопкой, без учета электропроводки",
    unit: "1 звонок и 1 кнопка",
    price: "741,00",
  },
  {
    title: "Монтаж второй и последующих кнопок для звонка или ремонт кнопки",
    unit: "1 кнопка",
    price: "260,00",
  },
];

const X29 = [
  {
    title: "К готовым креплениям",
    unit: "1 прибор",
    price: "1235,00",
  },
  {
    title: "С разметкой и установкой деталей крепления",
    unit: "1 прибор",
    price: "1650,00",
  },
];

const X30 = [
  {
    title:
      "Установка и подключение нестандартного оборудования на основе цены, указанной в чеке за покупку оборудования",
    unit: "1 оборудование",
    price: "20% от цены оборудования",
  },
];

const X31 = [
  {
    title: "Отбивка штукатурки стен и потолков",
    unit: "1 кв. м поверхности",
    price: "409,00",
  },
];

const X32 = [
  {
    title: "Стен",
    unit: "1 кв. м",
    price: "202,00",
  },
  {
    title: "Потолков",
    unit: "1 кв. м",
    price: "381,00",
  },
];

const X33 = [
  {
    title: "Стен",
    unit: "1 кв. м",
    price: "560,00",
  },
  {
    title: "Потолков",
    unit: "1 кв. м",
    price: "640,00",
  },
];

const X34 = [
  {
    title: "Стены",
    unit: "1 кв. м",
    price: "637,00",
  },
  {
    title: "Пола",
    unit: "1 кв. м",
    price: "305,00",
  },
  {
    title: "Облицовка стен керамической плиткой",
    unit: "1 кв. м",
    price: "1160,00",
  },
];

const X35 = [
  {
    title: "Карнизных или угловых (фасонных)",
    unit: "1 м",
    price: "943,00",
  },
  {
    title: "Цокольных или плинтусных",
    unit: "1 м",
    price: "943,00",
  },
  {
    title: "Специальных (мыльницы, полочки, крючки, бумагодержатели и т.п.)",
    unit: "1 плитка",
    price: "826,00",
  },
];

const X36 = [
  {
    title: "Полов",
    unit: "1 кв. м",
    price: "202,00",
  },
  {
    title: "Стен",
    unit: "1 кв. м",
    price: "449,00",
  },
  {
    title: "Дверей гладких",
    unit: "1 кв. м",
    price: "254,00",
  },
  {
    title: "Дверей филенчатых",
    unit: "1 кв. м",
    price: "384,00",
  },
  {
    title: "Потолков",
    unit: "1 кв. м",
    price: "553,00",
  },
  {
    title: "Окон",
    unit: "1 кв. м",
    price: "839,00",
  },
];

const X37 = [
  {
    title: "Стен",
    unit: "1 кв. м",
    price: "150,00",
  },
  {
    title: "Потолков",
    unit: "1 кв. м",
    price: "170,00",
  },
];

const X38 = [
  {
    title: "Стен",
    unit: "1 кв. м",
    price: "400,00",
  },
  {
    title: "Потолков",
    unit: "1 кв. м",
    price: "500,00",
  },
];

const X39 = [
  {
    title: "Стен",
    unit: "1 кв. м",
    price: "276,00",
  },
  {
    title: "Потолков",
    unit: "1 кв. м",
    price: "385,00",
  },
];

const X40 = [
  {
    title: "Радиаторов ребристых, труб, регистров",
    unit: "1 кв. м",
    price: "670,00",
  },
  {
    title: "Решеток трубопроводов диаметром до 50 мм",
    unit: "1 кв. м",
    price: "775,00",
  },
  {
    title: "Оклейка стен бумажными обоями",
    unit: "1 кв. м",
    price: "303,00",
  },
  {
    title: "Оклейка стен виниловыми или флизелиновыми обоями",
    unit: "1 кв. м",
    price: "449,00",
  },
  {
    title: "Снятие обоев и подготовка поверхности",
    unit: "1 кв. м",
    price: "471,00",
  },
  {
    title: "Оклейка потолков бумажными обоями",
    unit: "1 кв. м",
    price: "550,00",
  },
  {
    title: "Оклейка потолков виниловыми или флизелиновыми обоями",
    unit: "1 кв. м",
    price: "600,00",
  },
];

const X41 = [
  {
    title: "Механизированная",
    unit: "1 кв. м",
    price: "357,00",
  },
  {
    title: "Ручная",
    unit: "1 кв. м",
    price: "310,00",
  },
  {
    title: "Покрытие паркетных полов лаком за 3 раза по готовой поверхности",
    unit: "1 кв. м",
    price: "354,00",
  },
];

const X42 = [
  {
    title: "Врезного",
    unit: "1 прибор",
    price: "764,00",
  },
  {
    title: "Накладного",
    unit: "1 прибор",
    price: "350,00",
  },
  {
    title: "Смена оконных и дверных ручек",
    unit: "1 прибор",
    price: "305,00",
  },
  {
    title:
      "Вскрытие входной неметаллической двери (в случае утери жителями ключа), с последующей пристрожкой и подгонкой, с заготовкой вставки в обвязку полотна",
    unit: "1 дверной замок",
    price: "3000,00",
  },
];

const X43 = [
  {
    title: "Замена дверных полотен",
    unit: "1 полотно",
    price: "2000,00",
  },
  {
    title: "Врезка глазка во входную дверь квартиры",
    unit: "1 прибор",
    price: "600,00",
  },
  {
    title: "Смена в квартире разбитых жителями стекол",
    unit: "на 1 м фальца",
    price: "1460,00",
  },
  {
    title:
      "Замена уплотняющих прокладок в спаренных оконных переплетах и балконных дверных полотнах",
    unit: "1 м прокладки",
    price: "150,00",
  },
  {
    title: "Укрепление оконных и дверных наличников",
    unit: "1 м наличников",
    price: "111,00",
  },
  {
    title: "Смена досок в полах",
    unit: "1 м сменяемой доски",
    price: "453,00",
  },
];

const X44 = [
  {
    title: "Размер отдельного места до 0,5 кв. м",
    unit: "1 место",
    price: "1141,00",
  },
  {
    title: "Размер отдельного места до 1 кв. м",
    unit: "1 место",
    price: "1756,00",
  },
  {
    title: "Смена отдельных квадр щитового паркета",
    unit: "1 кв. м сменяемого пола",
    price: "825,00",
  },
];

const X45 = [
  {
    title: "Удаление старого и установка нового плинтуса",
    unit: "1 м плинтуса",
    price: "300,00",
  },
];

const X46 = [
  {
    title: "Узкие одинарные коробки для одного переплета",
    unit: "1 створка",
    price: "600,00",
  },
  {
    title: "Узкие одинарные коробки со спаренными переплетами",
    unit: "1 створка",
    price: "700,00",
  },
  {
    title: "Широкие составные коробки",
    unit: "1 створка",
    price: "800,00",
  },
];

const X47 = [
  {
    title: "Узкие одинарные коробки для одного переплета",
    unit: "1 створка",
    price: "500,00",
  },
  {
    title: "Узкие одинарные коробки со спаренными переплетами",
    unit: "1 створка",
    price: "600,00",
  },
  {
    title: "Широкие составные коробки",
    unit: "1 створка",
    price: "800,00",
  },
  {
    title: "Ремонт форточек (без смены стекла)",
    unit: "1 форточка",
    price: "1018,00",
  },
  {
    title: "Ремонт подоконных досок без снятия с места",
    unit: "1 м подоконной доски",
    price: "518,00",
  },
];

const X48 = [
  {
    title: "Снятие и установка вновь в каменной стене",
    unit: "1 м подоконной доски",
    price: "2470,00",
  },
];

const X49 = [
  {
    title:
      "Комплексный ремонт дверного полотна, со снятием старой краски и зачисткой поверхности",
    unit: "шт.",
    price: "4902,00",
  },
];

const X50 = [
  {
    title: "Одностворные",
    unit: "шт.",
    price: "900,00",
  },
  {
    title: "Двухстворные",
    unit: "шт.",
    price: "1050,00",
  },
];

const X51 = [
  {
    title: "Одностворные",
    unit: "шт.",
    price: "900,00",
  },
  {
    title: "Двухстворные",
    unit: "шт.",
    price: "1000,00",
  },
  {
    title:
      "Мелкий косметический ремонт двери (до 50% дверного полотна, без потребности в дополнительной подготовки полотна к ремонту)",
    unit: "шт.",
    price: "550,00",
  },
];

const X52 = [
  {
    title: "Одна",
    unit: "1 створка",
    price: "890,00",
  },
  {
    title: "Две",
    unit: "1 створка",
    price: "1780,00",
  },
];

const X53 = [
  {
    title: "Одна",
    unit: "1 полотно",
    price: "707,00",
  },
  {
    title: "Две",
    unit: "1 полотно",
    price: "1414,00",
  },
];

const X54 = [
  {
    title: "Смена обивки дверей",
    unit: "1 дверь",
    price: "1350,00",
  },
  {
    title: "В том числе снятие старой обивки",
    unit: "1 дверь",
    price: "1600,00",
  },
];

const X55 = [
  {
    title: "Настилка линолеума улучшенного качества с устройством плинтусов",
    unit: "1 кв. м линолеума",
    price: "690,00",
  },
  {
    title: "Смена вентиляционной решетки на кухне или в сантехнических узлах",
    unit: "1 решетка",
    price: "469.00",
  },
  {
    title: "Герметизация межпанельных швов",
    unit: "1 погонный метр шва",
    price: "1600,00",
  },
  {
    title: 'Комплексный ремонт квартиры "под ключ" (согласно смете)',
    unit: "1 кв. м. квартиры",
    price: "от 8300,00",
  },
];

export function Prices(): JSX.Element {
  return (
    <Layout>
      <FirstSection
        supertitle="Наша компания оказывает широкий спектр услуг"
        subTitle=""
        title=""
        hideButton
      />

      <FirstSection
        title="Санитарно-технические услуги"
        subTitle="Монтаж трубопровода:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={EVENT_LIST_1 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Замена или установка сантехприборов и водоразборной арматуры:"
        descriptionText="Унитазов и смывных бочков:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X2 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle=""
        descriptionText="Смесителей, кранов:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X3 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>

      <FirstSection
        title=""
        subTitle="Ремонт водоразборного крана без снятия с места:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X4 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Ремонт смесителя без снятия с места при смене прокладок:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X5 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>

      <FirstSection
        title=""
        subTitle="Ремонт смесителя без снятия с места при набивке сальника:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X6 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>

      <FirstSection title="" subTitle="Смена гибкой подводки:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X7 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Смена прокладки в соединении душа со смесителем:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X8 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>

      <FirstSection title="" subTitle="Смена душевой сетки:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X9 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Смена сифона:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X10 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Смена кронштейнов под санитарными приборами:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X11 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Прочистка и промывка сифонов санитарных приборов:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X12 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Устранение засоров, произошедших по вине проживающих:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X13 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Смена радиаторных блоков:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X14 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Установка стиральных машин:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X15 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Установка полотенцесущителей:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X16 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Прочие работы:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X17 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title="Электромонтажные работы"
        subTitle="Составление актов и проведение экспертиз:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X18 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Замена электропроводки:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X19 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Пробивка борозд глубиной до 40 мм для скрытой электропроводки, с последующей заделкой:"
        descriptionText="Отбойным молотком или иным техническим средством:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X20 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>

      <FirstSection
        title=""
        subTitle=""
        descriptionText="Вручную:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X21 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Проделывание отверстий диаметром до 12 мм в стенах толщиной до 180 мм, для прокладки электропроводки:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X22 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Проделывание отверстий для розеток и выключателей:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X23 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Смена неисправных выключателей, потолочных патронов, штепсельных розеток:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X24 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Замена автоматического выключателя:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X25 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Установка и замена клеммных колодок:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X26 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Монтаж боксов:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X27 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Монтаж звонков:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X28 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Подвеска светильников с лампами накаливания (энергосберегающими, светодиодными и др):"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X29 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Установка и подключение нестандартного оборудования:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X30 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title="Отделочные работы"
        subTitle="Отбивка штукатурки стен и потолков"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X31 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Перетирка штукатурки:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X32 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Ремонт штукатурки площадью до 10 м2 известковым раствором:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X33 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Разборка плиточной облицовки без сохранения материала:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X34 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Установка специальных плиток"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X35 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Улучшенная масляная окраска раннее окрашенных поверхностей с очисткой от загрязнений, расчисткой старой краски до 30 % и обработкой олифой:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X36 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Известковая окраска ранее окрашенных поверхностей:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X37 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Улучшенная клеевая окраска ранее окрашенных поверхностей:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X38 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Окраска поверхностей водоэмульсионной краской:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X39 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Окрашивание металлических поверхностей масляными составами:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X40 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Отделка поверхности паркетных полов, бывших в эксплуатации:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X41 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title="Плотничные, столярные и стекольные работы"
        subTitle="Смена неисправного замка:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X42 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X43 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Ремонт местами паркетных полов из штучного паркета:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X44 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Смена плинтусов:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X45 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Ремонт оконных переплетов:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X46 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Смена створок оконных переплетов:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X47 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Ремонт подоконных досок со снятием с места:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X48 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="" subTitle="Ремонт дверных полотен:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X49 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle=""
        descriptionText="На врезных шпонках или в наконечник:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X50 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle=""
        descriptionText="На планках:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X51 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle="Смена оконных и дверных петель:"
        descriptionText="Оконные длиной 100 мм при количестве сменяемых петель в створке:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X52 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle=""
        descriptionText="Дверные при количестве сменяемых петель в дверном полотне:"
        hideButton
      />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X53 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>

      <FirstSection title="" subTitle="Смена обивки дверей:" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X54 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection title="Прочие работы" subTitle="" hideButton />

      <SecondarySection>
        <div className={style.grid}>
          <EventsTable
            events={X55 as any}
            classname={style.upcomingEvents}
            caption="Наши услуги"
            showTags={false}
          />
        </div>
      </SecondarySection>
      <FirstSection
        title=""
        subTitle=""
        descriptionText="* в случае потребности в дополнительных работах по любым позициям сборника расценок может быть составлена смета"
        hideButton
      />
      <ContentImage image={IMG} className={style.contentImage} />
    </Layout>
  );
}
