import style from "./rating.module.scss";

import { ReactComponent as StarFilled } from "icons/starFilled.svg";
import { ReactComponent as StarStroke } from "icons/star.svg";

type Props = {
  rating: number;
};

const RATING_LENGTH = 5;

const starList = Array.from(
  { length: RATING_LENGTH },
  (item, index) => index + 1
);

export function Rating({ rating }: Props): JSX.Element {
  return (
    <div className={style.rating}>
      {starList.map((item) => {
        const isStroke = item > rating;

        return isStroke ? (
          <StarStroke className={style.star} key={item} />
        ) : (
          <StarFilled className={style.star} key={item} />
        );
      })}
    </div>
  );
}
