import classNames from 'classnames';
import { ReactNode, createElement, forwardRef, Ref } from 'react';

import style from './section-layout.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  as?: 'div' | 'section';
  isFirstSection?: boolean;
} & (JSX.IntrinsicElements['div'] | JSX.IntrinsicElements['section']);

type SectionLayoutdRef = Ref<HTMLDivElement | HTMLElement>;

export const SectionLayout = forwardRef(
  (
    { children, className = undefined, as = 'section', isFirstSection = false, ...props }: Props,
    ref: SectionLayoutdRef,
  ): JSX.Element =>
    createElement(
      as,
      {
        className: classNames(
          style.sectionLayout,
          className,
          { [style.firstSection]: isFirstSection },
          { [style.div]: as !== 'section' },
        ),
        ref,
        ...props,
      },
      children,
    ),
);

SectionLayout.displayName = 'CardLayout';
