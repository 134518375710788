import classNames from "classnames";

import { Row } from "./row";
import { Props } from "./table.config";
import style from "./table.module.scss";

export function Table({
  events,
  caption,
  isPast = false,
  classname = "",
}: Props): JSX.Element {
  return (
    <table className={classNames(style.table, classname)}>
      <caption
        className={classNames(style.pastEvents, { "visually-hidden": !isPast })}
      >
        {caption}
      </caption>
      <thead
        className={classNames(style.tableHead, { "visually-hidden": isPast })}
      >
        <tr>
          <th scope="col" className={style.headCell}>
            Наименование работ*
          </th>
          <th scope="col" className={style.headCell}>
            Единица измерения
          </th>
          <th scope="col" className={style.headCell}>
            Стоимость работ (руб), с учетом НДС
          </th>
        </tr>
      </thead>
      <tbody className={style.tableBody}>
        {events.map(({ title, unit, price }) => (
          <Row key={title} title={title} unit={unit} price={price} />
        ))}
      </tbody>
    </table>
  );
}
