import { ValuesOf } from "shared/config";

export const ImageSize = {
  S: "S",
  L: "L",
} as const;

type ValuesOfImgSize = ValuesOf<typeof ImageSize>;

export type Image = {
  size: ValuesOfImgSize;
  src: string;
  alt: string;
};

export type Props = {
  title: string;
  href: string;
  imageData?: Image;
  label?: string;
  className?: string;
  withoutArrow?: boolean;
};
