const ICON_BASE_URL = '/icons';
const IMAGE_BASE_URL = '/images/pages/main';

export type IntegrationsIcon = {
  src: string;
  alt: string;
  href?: string;
};

export const iconList: IntegrationsIcon[] = [
  {
    src: `${ICON_BASE_URL}/salesforce.svg`,
    alt: 'Salesforce',
  },
  {
    src: `${ICON_BASE_URL}/zendesk.svg`,
    alt: 'zendesk',
  },
  {
    src: `${IMAGE_BASE_URL}/zoiper-logo.webp`,
    alt: 'zoiper',
  },
  {
    src: `${IMAGE_BASE_URL}/skale-logo.webp`,
    alt: 'skale',
  },
  {
    src: `${ICON_BASE_URL}/sugarcrm.svg`,
    alt: 'sugarcrm',
  },
  {
    src: `${ICON_BASE_URL}/zoho.svg`,
    alt: 'zoho',
  },
  {
    src: `${ICON_BASE_URL}/hubspot.svg`,
    alt: 'hubspot',
  },
  {
    src: `${ICON_BASE_URL}/power-bi.svg`,
    alt: 'power-bi',
  },
  {
    src: `${ICON_BASE_URL}/pipedrive.svg`,
    alt: 'pipedrive',
  },
  {
    src: `${ICON_BASE_URL}/aws.svg`,
    alt: 'aws',
  },
  {
    src: `${ICON_BASE_URL}/profit.svg`,
    alt: 'profit',
  },
  {
    src: `${ICON_BASE_URL}/panda.svg`,
    alt: 'panda',
  },
];
