import { AriaAttributes, MouseEventHandler, ReactNode } from "react";

import { ValuesOf } from "shared/config";

export const ButtonSize = {
  L: "L",
  M: "M",
  S: "S",
};

export type ValuesOfButtonSize = ValuesOf<typeof ButtonSize>;

export const ButtonColorList = {
  GRADIENT: "gradient",
  PRIMARY: "primary",
  WHITE: "white",
  BLACK: "black",
};

export type ValuesOfButtonColorList = ValuesOf<typeof ButtonColorList>;

export const ButtonTypeList = {
  FILL: "fill",
  STROKE: "stroke",
  GHOST: "ghost",
};

export type ValuesOfButtonTypeList = ValuesOf<typeof ButtonTypeList>;

export type ButtonProps = {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "reset" | "button";
  isLoading?: boolean;
};

export type LinkProps = {
  href: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  scroll?: boolean;
};

export type Props<T> = {
  variantType: ValuesOfButtonTypeList;
  color: ValuesOfButtonColorList;
  size: ValuesOfButtonSize;
  text?: string | number;
  classname?: string;
  withArrow?: boolean;
  leftArrow?: boolean;
  children?: ReactNode;
} & AriaAttributes &
  T;

export type ButtonBodyProps = Partial<
  Pick<
    Props<ButtonProps>,
    "isLoading" | "text" | "withArrow" | "leftArrow" | "children"
  >
>;
