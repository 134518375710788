import { FocusEventHandler, FormEvent, FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import {
  DEFAULT_IS_REQUIRED_OPTION,
  DEFAULT_OPTIONS,
  Errors,
  FieldsMap,
  FieldsState,
  FormElement,
  InvalidEventHandler,
  Register,
  State,
  SubmitHandler,
  ValidationMode,
  ValidationSource,
  ValuesOfState,
  ValuesOfValidationSource,
} from './use-form.config';

export function useForm<Fields extends Record<string, string | boolean>>(formName: string) {
  const fieldsMap = useRef<FieldsMap<keyof Fields>>(new Map());
  const validationSource = useRef<ValuesOfValidationSource | null>(null);
  const firstInvalidFieldName = useRef<keyof Fields | null>(null);
  const [removeSubscriptionFunctions, setRemoveSubscriptionFunctions] = useState<(() => void)[]>([]);

  const [state, setState] = useState<ValuesOfState>(State.IDLE);
  const [fieldsState, setFieldsState] = useState<FieldsState<keyof Fields>>({});
  const [errors, setErrors] = useState<Errors<keyof Fields>>({});

  useEffect(
    () => () => {
      removeSubscriptionFunctions.forEach((removeSubscription) => {
        removeSubscription();
      });
    },
    [removeSubscriptionFunctions],
  );

  useEffect(() => {
    const isFormValidating = Object.values(fieldsState).some((fieldState) => fieldState === State.VALIDATING);

    setState(isFormValidating ? State.VALIDATING : State.IDLE);
  }, [fieldsState]);

  const register = useCallback<Register<keyof Fields>>(
    (name, options = DEFAULT_OPTIONS) => {
      const { builtInValidations = {}, customValidations = [] } = options;
      const { isRequired = DEFAULT_IS_REQUIRED_OPTION, isValueMatchType = false } = builtInValidations;

      const runCustomValidations = async (value: string | boolean): Promise<boolean> => {
        if (customValidations.length === 0) {
          return true;
        }

        setFieldsState((previousState) => ({
          ...previousState,
          [name]: State.VALIDATING,
        }));

        const validationResults = await Promise.allSettled(customValidations.map(({ validate }) => validate(value)));

        setFieldsState((previousState) => ({
          ...previousState,
          [name]: State.IDLE,
        }));

        const validationErrors = validationResults
          .filter((result) => (result.status === 'fulfilled' ? !!result.value : false))
          .map((result) => (result.status === 'fulfilled' ? result.value : null));
        const hasErrors = validationErrors.length > 0;

        if (!hasErrors) {
          return true;
        }

        firstInvalidFieldName.current ??= name;
        setErrors((previousErrors) => ({
          ...previousErrors,
          [name]: validationErrors,
        }));

        return false;
      };

      const invalidHandler: InvalidEventHandler = (event) => {
        const fieldErrors: string[] = [];

        if (event.currentTarget.validity.valid) {
          return;
        }

        firstInvalidFieldName.current ??= name;

        if (
          isRequired &&
          !(isRequired.mode === ValidationMode.ON_SUBMIT && validationSource.current === ValidationSource.BLUR) &&
          event.currentTarget.validity.valueMissing
        ) {
          fieldErrors.push(isRequired.message);
        }

        if (
          isValueMatchType &&
          !(isValueMatchType.mode === ValidationMode.ON_SUBMIT && validationSource.current === ValidationSource.BLUR) &&
          event.currentTarget.validity.typeMismatch
        ) {
          fieldErrors.push(isValueMatchType.message);
        }

        setErrors((previousErrors) => {
          const fieldPreviousErrors = previousErrors?.[name] ?? [];
          const fieldNewErrors = [...new Set([...fieldPreviousErrors, ...fieldErrors])];

          return {
            ...previousErrors,
            [name]: fieldNewErrors,
          };
        });
      };

      const blurHandler: FocusEventHandler<FormElement> = async (event) => {
        validationSource.current = ValidationSource.BLUR;

        const hasOnBlurBuiltInValidations = Object.values(builtInValidations).some((option) => {
          if (option === false) {
            return false;
          }

          return option.mode === ValidationMode.ON_BLUR_AND_SUBMIT;
        });

        const hasOnBlurCustomValidations = customValidations.some(
          ({ mode }) => mode === ValidationMode.ON_BLUR_AND_SUBMIT,
        );

        const hasOnBlurValidations = hasOnBlurBuiltInValidations || hasOnBlurCustomValidations;

        if (!hasOnBlurValidations) {
          return;
        }

        if (hasOnBlurBuiltInValidations) {
          const isFieldInvalid = !event.currentTarget.checkValidity();

          if (isFieldInvalid) {
            return;
          }
        }

        if (hasOnBlurCustomValidations) {
          await runCustomValidations(event.currentTarget.value);
        }
      };

      const inputHandler = () => {
        setErrors((previousErrors) => {
          const hasErrors = !!previousErrors[name]?.length;

          if (!hasErrors) {
            return previousErrors;
          }

          return {
            ...previousErrors,
            [name]: null,
          };
        });
      };

      const ref = (node: FormElement | null) => {
        if (!node || fieldsMap.current.has(name)) {
          return;
        }

        node.addEventListener('invalid', invalidHandler as never);
        node.addEventListener('blur', blurHandler as never);
        node.addEventListener('input', inputHandler);

        const removeSubscription = () => {
          node.removeEventListener('invalid', invalidHandler as never);
          node.removeEventListener('blur', blurHandler as never);
          node.removeEventListener('input', inputHandler);
        };

        fieldsMap.current.set(name, {
          ref: node,
          validate: runCustomValidations,
        });
        setRemoveSubscriptionFunctions((previousState) => [...previousState, removeSubscription]);
      };

      return {
        ref,
        name,
        required: !!isRequired,
      };
    },
    [formName],
  );

  const submitForm = useCallback(async (event: FormEvent<HTMLFormElement>, submitHandler: SubmitHandler<Fields>) => {
    event.preventDefault();

    validationSource.current = ValidationSource.SUBMIT;

    const validationResults = await Promise.allSettled(
      [...fieldsMap.current.values()].map(({ validate, ref }) => ref.checkValidity() && validate(ref.value)),
    );
    const hasErrors = validationResults.some((result) => (result.status === 'fulfilled' ? !result.value : true));

    if (firstInvalidFieldName.current) {
      fieldsMap.current.get(firstInvalidFieldName.current)?.ref.focus();
      firstInvalidFieldName.current = null;
    }

    if (hasErrors) {
      return;
    }

    const valueEntries = [...fieldsMap.current.entries()].map(([name, { ref }]) => [name, ref.value]);

    submitHandler(Object.fromEntries(valueEntries), event);
  }, []);

  const createSubmitHandler = useCallback(
    (handler: SubmitHandler<Fields>): FormEventHandler<HTMLFormElement> =>
      (event) => {
        submitForm(event, handler);
      },
    [submitForm],
  );

  const setValue = useCallback((name: keyof Fields, value: Fields[keyof Fields], overwrite = true) => {
    const field = fieldsMap.current.get(name);

    if (!field) {
      return;
    }

    if (!overwrite && !field.ref.validity.valueMissing) {
      return;
    }

    if (typeof value === 'boolean' && field.ref instanceof HTMLInputElement) {
      field.ref.checked = value;
    }

    if (typeof value === 'string') {
      field.ref.value = value;
    }

    field.ref.dispatchEvent(new Event('input'));
  }, []);

  return {
    register,
    createSubmitHandler,
    setValue,
    errors,
    state,
    fieldsState,
  };
}
