import classNames from "classnames";
import { useContext, useEffect, useRef } from "react";

import { ReactComponent as ArrowDropdown } from "icons/arrowDropdown.svg";

import {
  HeaderApiContext,
  HeaderStateContext,
} from "../../../../../../contexts/header-context";
import { LinksGroup } from "../../../links-group";

import styles from "./links-accordion.module.scss";
import { Props } from "./links-accordion.types";

export function LinksAccordion({
  id,
  title,
  linkGroups,
  isOneColumn = false,
}: Props): JSX.Element {
  const { openedMenuGroupId } = useContext(HeaderStateContext);
  const { openMenuGroup, closeMenuGroup } = useContext(HeaderApiContext);

  const linksRef = useRef<HTMLDivElement | null>(null);

  const setHeight = () => {
    if (!linksRef.current) {
      return;
    }

    linksRef.current.style.setProperty(
      "--header-links-accordion-links-max-height",
      `${linksRef.current.scrollHeight}px`
    );
  };

  useEffect(() => {
    document.fonts.addEventListener("loadingdone", setHeight);

    return () => {
      document.fonts.removeEventListener("loadingdone", setHeight);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setHeight);

    return () => {
      window.removeEventListener("resize", setHeight);
    };
  }, []);

  const isExpanded = id === openedMenuGroupId;
  const linksId = `${id}-links-accordion`;
  const isGroupTitleHidden = linkGroups.length === 1;

  const buttonClickHandler = () => {
    if (isExpanded) {
      closeMenuGroup();

      return;
    }

    openMenuGroup(id);
  };

  return (
    <>
      <button
        className={classNames(styles.button, "second-text")}
        type="button"
        aria-expanded={isExpanded}
        aria-controls={linksId}
        onClick={buttonClickHandler}
      >
        {title}

        <ArrowDropdown className={styles.icon} aria-hidden />
      </button>

      <div
        id={linksId}
        className={classNames(styles.links, {
          [styles.hiddenLinks]: !isExpanded,
        })}
        ref={linksRef}
      >
        <LinksGroup
          className={styles.linksGroup}
          groups={linkGroups}
          isGroupTitleHidden={isGroupTitleHidden}
          isOneColumn={isOneColumn}
        />
      </div>
    </>
  );
}
