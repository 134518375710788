const IMAGE_BASE_URL = '/images/pages/new/main';

export const SECOND_IMAGE_MAIN = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/main-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/main-sm@2x.webp`,
    width: '343',
    height: '213',
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/main-md@1x.webp`,
    retina: `${IMAGE_BASE_URL}/main-md@2x.webp`,
    width: '343',
    height: '213',
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/phone-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/phone-lg@2x.webp`,
    width: '178',
    height: '318',
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/phone-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/phone-lg@2x.webp`,
    width: '178',
    height: '318',
  },
  alt: 'Phone active call',
};
