import classNames from "classnames";

import style from "./button.module.scss";

import { ReactComponent as ArrowRight } from "icons/arrowRight.svg";
import { ReactComponent as ArrowHorizontal } from "icons/horizontalArrow.svg";

type PrimaryButtonProps = {
  text: string | JSX.Element;
  href?: string;
  withArrow?: boolean;
  htmlType?: "button" | "submit" | "reset";
  color?: "blue" | "white";
  hoverWhite?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  isFullWidth?: boolean;
  withoutBackground?: boolean;
  isLoading?: boolean;
};

type SecondaryButtonProps = {
  text: string;
  href?: string;
  withArrow?: boolean;
  htmlType?: "button" | "submit" | "reset";
  color?: "blue" | "white" | "black";
  hoverWhite?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  mobile?: boolean;
  isFullWidth?: boolean;
  withoutBackground?: boolean;
};

type TextButtonProps = {
  text: string;
  href?: string;
  withArrow?: boolean;
  htmlType?: "button" | "submit" | "reset";
  withHorizontalArrow?: boolean;
  color?: "blue" | "white";
  hoverWhite?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  className?: string;
  download?: boolean;
  isFullWidth?: boolean;
};

type TransparentButtonProps = {
  text: string;
  href?: string;
  withArrow?: boolean;
  htmlType?: "button" | "submit" | "reset";
  color?: "black" | "white" | "blue" | null;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  hoverWhite?: boolean;
  mobile?: boolean;
  withoutBorder?: boolean;
  className?: string;
  isFullWidth?: boolean;
};

type ButtonTemplateProps = {
  text: string | JSX.Element;
  href?: string;
  withArrow?: boolean;
  withHorizontalArrow?: boolean;
  htmlType?: "button" | "submit" | "reset";
  classname: string;
  isText?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  download?: boolean;
  isFullWidth?: boolean;
};

type ButtonCarouselProps = {
  className?: boolean;
  buttonColor: "black" | "white";
  text: string;
  withBorder?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

function ButtonTemplate({
  text,
  href = "",
  withArrow = false,
  withHorizontalArrow = false,
  onClick = undefined,
  classname,
  isText = false,
  disabled = false,
  download = false,
  htmlType = "button",
  isFullWidth = false,
}: ButtonTemplateProps): JSX.Element {
  const childrenWithLink = isText ? (
    <a
      download={download}
      href={href}
      onClick={onClick}
      className={classNames(style.button, classname)}
    >
      <div className={style.textContainer}>
        {text}
        {withArrow && (
          <ArrowRight className={classNames(style.svg, style.icon)} />
        )}
        {withHorizontalArrow && (
          <ArrowHorizontal
            className={classNames(style.horizontalIcon, style.svg)}
          />
        )}
      </div>
      {isText && <div className={style.underline} />}
    </a>
  ) : (
    <a
      download={download}
      href={href}
      onClick={onClick}
      className={classNames(style.linkButton, {
        [style.fullWidth]: isFullWidth,
      })}
    >
      <div className={style.buttonWrapper}>
        <div className={classNames(style.button, classname)}>
          <div className={style.textContainer}>
            {text}
            {withArrow && <ArrowRight className={classNames(style.icon)} />}
          </div>
        </div>
      </div>
    </a>
  );
  // eslint-disable-next-line no-nested-ternary
  return href ? (
    // eslint-disable-next-line no-nested-ternary
    download ? (
      <a
        download={download}
        href={download ? href : ""}
        target="_blank"
        rel="noreferrer"
        className={classNames(style.button, classname)}
        onClick={onClick}
      >
        <div className={style.textContainer}>
          {text}
          {withArrow && <ArrowRight className={classNames(style.icon)} />}
        </div>
        {isText && <div className={style.underline} />}
      </a>
    ) : href[0] === "#" ? (
      childrenWithLink
    ) : (
      <a href={href}>{childrenWithLink}</a>
    )
  ) : (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
      className={classNames(style.buttonWrapper, {
        [style.fullWidth]: isFullWidth,
      })}
    >
      <span className={classNames(style.button, classname)}>
        <span className={style.textContainer}>
          {text}
          {withArrow && <ArrowRight className={style.svg} />}
        </span>
        {isText && <div className={style.underline} />}
      </span>
    </button>
  );
}

function Primary({
  color,
  hoverWhite = false,
  className = undefined,
  disabled = false,
  withoutBackground = false,
  ...props
}: PrimaryButtonProps): JSX.Element {
  return (
    <ButtonTemplate
      classname={classNames(
        style.button,
        style.primary,
        className,
        { [style.white]: color === "white" },
        { [style.hoverWhite]: color !== "white" && hoverWhite },
        { [style.disabled]: disabled },
        { [style.withoutBackground]: withoutBackground }
      )}
      {...props}
    />
  );
}

function Secondary({
  color = "blue",
  hoverWhite = false,
  mobile = false,
  className = "",
  withoutBackground = false,
  ...props
}: SecondaryButtonProps): JSX.Element {
  return (
    <ButtonTemplate
      classname={classNames(
        style.secondary,
        {
          [style.white]: color === "white",
          [style.black]: color === "black",
          [style.mobile]: mobile,
        },
        { [style.hoverWhite]: color !== "white" && hoverWhite },
        { [style.withoutBackground]: withoutBackground },
        className
      )}
      {...props}
    />
  );
}

function Text({
  hoverWhite = false,
  color = "blue",
  className = "",
  download = false,
  ...props
}: TextButtonProps): JSX.Element {
  return (
    <ButtonTemplate
      download={download}
      classname={classNames(
        style.text,
        className,
        { [style.white]: color === "white" },
        { [style.hoverWhite]: color !== "white" && hoverWhite }
      )}
      {...props}
      isText
    />
  );
}

function Transparent({
  color = "blue",
  hoverWhite = false,
  withoutBorder = false,
  mobile = false,
  className = "",
  ...props
}: TransparentButtonProps): JSX.Element {
  return (
    <ButtonTemplate
      classname={classNames(
        style.secondary,
        className,
        { [style.mobile]: mobile },
        style.transparent,
        {
          [style.withoutBorder]: withoutBorder,
          [style.white]: color === "white",
          [style.blue]: color === "blue",
          [style.black]: color === "black",
          [style.transparentHoverWhite]: hoverWhite,
        }
      )}
      {...props}
    />
  );
}

function Carousel({
  buttonColor = "black",
  className = undefined,
  withBorder = false,
  text,
  onClick,
}: ButtonCarouselProps): JSX.Element {
  return (
    <button
      type="button"
      className={classNames(style.buttonCarousel, {
        [style.activeCarousel]: className,
        [style.withBorder]: withBorder,
        [style.whiteCarousel]: buttonColor === "white",
      })}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export const Button = {
  Primary,
  Secondary,
  Text,
  Transparent,
  Carousel,
};
