import classNames from 'classnames';

import { colorList, colorToClassName, LoaderProps } from './loader.config';
import style from './loader.module.scss';

export function Loader({ className = '', color = colorList.black, strokeWidth = 1 }: LoaderProps): JSX.Element {
  return (
    <svg className={classNames(style.svg, style.loader, className, colorToClassName[color])} viewBox="0 0 24 24">
      <circle
        className={style.circle}
        cx="12"
        cy="12"
        r="10"
        fill="none"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
}
