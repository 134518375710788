import { FormEventHandler, useState } from "react";

type FormState<S, K extends keyof S = keyof S> = Record<
  K,
  { value: S[K]; error: string }
>;

type Validator =
  | ((value: string) => string)
  | ((value: string) => Promise<string>);

type Options<S> = {
  validators: Partial<Record<keyof S, Validator>>;
  validateOnSubmit?: boolean;
};

export const useDeprecatedForm = <S extends Record<string, string>>(
  initialState: S,
  options?: Options<S>
) => {
  const validators = options?.validators;

  const [formState, setFormState] = useState<FormState<S>>(
    Object.fromEntries(
      Object.entries(initialState).map(([key, value]) => [
        key,
        {
          value,
          error: "",
        },
      ])
    ) as FormState<S>
  );

  const errors = Object.fromEntries(
    Object.entries(formState).map(([key, value]) => [key, value.error])
  ) as Record<keyof S, string>;
  const values = Object.fromEntries(
    Object.entries(formState).map(([key, value]) => [key, value.value])
  ) as Record<keyof S, S[keyof S]>;

  const isValid = !Object.values(errors).filter((error) => error).length;

  return {
    errors,
    values,
    isValid,
    handleSubmit: (handler: () => void) =>
      (async (e) => {
        e.preventDefault();
        const allErrors: Record<string, string | Promise<string>> = {};
        if (validators) {
          Object.entries(validators).forEach(([key, validator]) => {
            allErrors[key] = validator!(formState[key].value);
          });
        }

        const hasErrors = Boolean(
          Object.values(allErrors).filter((error) => error).length
        );
        if (hasErrors) {
          const asyncErrors = Object.fromEntries(
            Object.entries(allErrors).filter(
              ([, error]) => error instanceof Promise
            )
          );
          const syncErrors = Object.fromEntries(
            Object.entries(allErrors).filter(
              ([, error]) => !(error instanceof Promise)
            )
          );

          const syncFormState = Object.fromEntries(
            Object.entries(formState).map(([key, entry]) => [
              key,
              { ...entry, error: syncErrors[key] || entry.error },
            ])
          ) as FormState<S>;
          setFormState(syncFormState);
          const asyncFormState = Object.fromEntries(
            await Promise.all(
              Object.entries(syncFormState).map(async ([key, entry]) => [
                key,
                { ...entry, error: (await asyncErrors[key]) || entry.error },
              ])
            )
          ) as FormState<S>;
          setFormState(asyncFormState);

          if (
            !Object.values(asyncFormState).filter((entry) => entry.error).length
          ) {
            handler();
          }
        } else {
          handler();
        }
      }) as FormEventHandler<HTMLFormElement>,
    setError: (key: keyof S) => (error?: string) => {
      setFormState({ ...formState, [key]: { ...formState[key], error } });
    },
    changeValue: (key: keyof S) => async (value: string) => {
      let { error } = formState[key];

      if (!options?.validateOnSubmit && validators && validators[key]) {
        error = await validators[key]!(value);
      } else if (options?.validateOnSubmit && error) {
        error = "";
      }
      setFormState({
        ...formState,
        [key]: { error, value },
      });
    },
  };
};

export const notRequiredValidator = (value: string): string => {
  if (!value) {
    return "Required field";
  }

  return "";
};

export const phoneMask =
  (setValue: (val: string) => void, prevValue: string) => (value: string) => {
    if (!value) {
      setValue(value);
      return;
    }
    const val = value[0] === "+" || !value ? value : `+${value}`;
    setValue(/^\+\d+$|^\+$/.test(val) && val.length < 16 ? val : prevValue);
  };

export const sendForm = (
  url: string,
  payload: Record<string, string | number>,
  withBodyResponse?: boolean
) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(async (res) => ({
      ...res,
      status: res.status,
      ...(withBodyResponse && { data: await res.json() }),
    }))
    .catch((err) => err);
};
