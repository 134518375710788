import cn from "classnames";
import { FC } from "react";

import style from "./headers.module.scss";

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  color?: "black" | "grey" | "white" | "whiteSecondary";
  text: JSX.Element | string;
  subheader?: string;
  className?: string;
  titleClass?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  colorMain?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  topSubheader?: boolean;
  children?: any;
};

export const H1: FC<Props> = ({
  text,
  subheader,
  className,
  color = "white",
  titleClass = "",
}) => (
  <div
    className={cn(style.h1, className, {
      [style[`${color}`]]: color,
    })}
  >
    {subheader && <h3 className={style.subheader}>{subheader}</h3>}
    <h1 className={cn(style.text, titleClass)}>{text}</h1>
  </div>
);

const H2: FC<Props> = ({
  color = "black",
  topSubheader = false,
  text,
  subheader,
  className,
  titleClass = "",
}) => (
  <div
    className={cn(style.h2, className, {
      [style[`${color}`]]: color,
    })}
  >
    {subheader && topSubheader && (
      <p className={style.topSubheader}>{subheader}</p>
    )}
    <h2 className={cn(style.text, titleClass)}>{text}</h2>
    {subheader && !topSubheader && (
      <p className={style.subheader}>{subheader}</p>
    )}
  </div>
);

const H3: FC<Props> = ({
  color = "black",
  children,
  colorMain = false,
  text,
  subheader,
  className,
  titleClass = "",
}) => (
  <div
    className={cn(style.h3, className, {
      [style[`${color}`]]: color,
      [style.colorMain]: colorMain,
    })}
  >
    <h3 className={cn(style.text, titleClass)}>{text}</h3>
    {subheader && <p className={style.subheader}>{subheader}</p>}
    {children}
  </div>
);

export const Header = {
  H1,
  H2,
  H3,
};
