import classNames from "classnames";
import { ChangeEvent, useState, useRef } from "react";

import { ValuesOf } from "../shared/config";
import { sendForm } from "../shared/lib/form";
import { PopupError } from "../shared/lib/popup-error";
import { PopupThankYou } from "../shared/lib/popup-thank-you";
import { FormState, FormValidationMode, useForm } from "../shared/lib/use-form";

import { Input } from "./components/input";
import styles from "./new-form.module.scss";
import {
  SecondarySection,
  UiButton,
  ButtonSize,
  ButtonTypeList,
  ButtonColorList,
} from "../shared/ui";
import { Textarea } from "./components/textarea";

type Form = {
  email: string;
  name: string;
  phone: string;
  request: string;
};

export const formsNamesList = {
  startFreeTrial: "start-free-trial",
  requestDemo: "request-a-demo",
} as const;

export type ValuesOfFormNamesList = ValuesOf<typeof formsNamesList>;

export type Props = {
  ordinaryTitle: string;
  gradientTitle?: string;
  description: string;
  buttonText: string;
  formName: ValuesOfFormNamesList;
  classname?: string;
};

export function NewForm({
  ordinaryTitle,
  gradientTitle = "",
  description,
  buttonText,
  formName,
  classname = "",
}: Props) {
  const { register, errors, createSubmitHandler, setValue, state } =
    useForm<Form>(formName);

  const phoneInput = useRef<string>("");

  const [isVisiblePlus, setIsVisiblePlus] = useState(false);
  const [textErrorPhone, setTextErrorPhone] = useState("");
  const [popupErrorText, setPopupErrorText] = useState("");

  const [isVisiblePopupThankYou, setIsVisiblePopupThankYou] = useState(false);
  const [isVisiblePopupError, setIsVisiblePopupError] = useState(false);

  const isFormLoading = state === FormState.VALIDATING;

  const phoneInputProps = register("phone", {
    builtInValidations: {
      isRequired: {
        message: "Это поле обязательно",
        mode: FormValidationMode.ON_BLUR_AND_SUBMIT,
      },
    },
  });

  const emailInputProps = register("email", {
    builtInValidations: {
      isValueMatchType: {
        message: "Некорректный email",
        mode: FormValidationMode.ON_BLUR_AND_SUBMIT,
      },
    },
  });
  const emailFirstError = errors.email?.at(0);

  const requestInputProps = register("request", {
    builtInValidations: {
      isRequired: {
        message: "Это поле обязательно",
        mode: FormValidationMode.ON_BLUR_AND_SUBMIT,
      },
    },
  });

  const nameInputProps = register("name", {
    builtInValidations: {
      isRequired: {
        message: "Это поле обязательно",
        mode: FormValidationMode.ON_BLUR_AND_SUBMIT,
      },
    },
  });

  const nameFirstError = errors.name?.at(0);

  const phoneNumberFirstError = errors.phone?.at(0);

  const isFormDisabled =
    !!nameFirstError || !!phoneNumberFirstError || !!emailFirstError;

  const submitHandler = createSubmitHandler(
    async ({ name, email, phone, request }) => {
      const payload = { name, email, phone: `+${phone}`, request };

      try {
        const res = await sendForm("/api/request", payload);

        if (res instanceof Error || !res?.status?.toString().startsWith("2")) {
          setPopupErrorText("Ошибка. Уже работаем над устранением");
          setIsVisiblePopupError(true);
          return;
        }

        setIsVisiblePopupThankYou(true);
        setValue("name", "");
        setValue("phone", "");
        setValue("request", "");
        setValue("email", "");

        setIsVisiblePlus(false);
      } catch (error) {
        console.log(error);
      }
    }
  );

  const onChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.value.trim();
    const valueWithoutProhibitedSymbols = `${currentValue.replace(/\D/g, "")}`;

    if (currentValue !== valueWithoutProhibitedSymbols) {
      setTextErrorPhone("Please write only numbers");
    } else {
      setTextErrorPhone("");
    }

    phoneInput.current = valueWithoutProhibitedSymbols;
    setValue("phone", valueWithoutProhibitedSymbols, true);
  };

  const onFocusPhone = () => {
    if (!isVisiblePlus) setIsVisiblePlus(true);
  };

  const onBlurPhone = () => {
    if (phoneInput.current === "") {
      setIsVisiblePlus(false);
    }
    setTextErrorPhone("");
  };

  return (
    <SecondarySection
      id={formName}
      className={classNames(styles.section, classname)}
      isVerticalPadding64
    >
      <div className={styles.grid}>
        <div
          className={classNames(
            styles.card,
            {
              [styles.cardGradientRequestDemo]:
                formName === formsNamesList.requestDemo,
            },
            {
              [styles.cardGradientStartFreeTrial]:
                formName === formsNamesList.startFreeTrial,
            }
          )}
        >
          <div className={styles.titleAndDescr}>
            <h2 className="h2">
              {ordinaryTitle}
              {gradientTitle && (
                <span className={styles.gradientText}> {gradientTitle} </span>
              )}
            </h2>

            <p className={classNames("body-text", styles.description)}>
              {description}
            </p>
          </div>

          <div className={styles.wrapper}>
            <form
              className={classNames(styles.form)}
              onSubmit={submitHandler}
              noValidate
            >
              <Input
                className={styles.input}
                type="text"
                label="ФИО"
                placeholder="ФИО"
                errorMessage={nameFirstError}
                {...nameInputProps}
              />

              <Input
                className={styles.input}
                type="tel"
                label="Номер телефона"
                placeholder="Номер телефона"
                errorMessage={phoneNumberFirstError || textErrorPhone}
                isVisiblePlus={isVisiblePlus}
                onChange={onChangePhone}
                onFocus={onFocusPhone}
                onBlur={onBlurPhone}
                isInputPhone
                {...phoneInputProps}
              />

              <Input
                className={styles.input}
                type="email"
                label="Email"
                placeholder="example@example.com"
                errorMessage={emailFirstError}
                {...emailInputProps}
              />

              <Textarea
                height={400}
                className={styles.input}
                label="Текст заявки"
                placeholder="Текст заявки"
                {...requestInputProps}
              />

              <UiButton
                classname={styles.button}
                size={ButtonSize.L}
                variantType={ButtonTypeList.FILL}
                color={ButtonColorList.PRIMARY}
                isLoading={isFormLoading}
                disabled={isFormDisabled}
                text={buttonText}
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>

      <PopupThankYou
        isVisiblePopup={isVisiblePopupThankYou}
        setIsVisiblePopup={setIsVisiblePopupThankYou}
      />
      <PopupError
        isVisiblePopup={isVisiblePopupError}
        setIsVisiblePopup={setIsVisiblePopupError}
        textError={popupErrorText}
      />
    </SecondarySection>
  );
}
