import { ComponentType, SVGAttributes } from "react";
import { ReactComponent as zoomStar } from "icons/zoomStar.svg";
import { ReactComponent as connection } from "icons/connection.svg";
import { ReactComponent as dashboard } from "icons/dashboard.svg";
import { ReactComponent as recording } from "icons/recording.svg";
import { ReactComponent as callback } from "icons/callback.svg";
import { ReactComponent as settings } from "icons/settings.svg";

import { ValuesOf } from "shared/config";

export type SvgFeaturesProps = {
  title?: string;
  svgFeatures: SvgFeature[];
  className?: string;
};

export type SvgFeature = {
  image?: ValuesOfSvgList;
  title: string;
  description: string | JSX.Element;
};

const svgList = {
  Recording: "Recording",
  Connection: "Connection",
  Dashboard: "Dashboard",
  ZoomStar: "ZoomStar",
  Callback: "Callback",
  Settings: "Settings",
} as const;

type ValuesOfSvgList = ValuesOf<typeof svgList>;

export const svgComponents: Record<
  ValuesOfSvgList,
  ComponentType<SVGAttributes<SVGAElement>>
> = {
  [svgList.ZoomStar]: zoomStar,
  [svgList.Connection]: connection,
  [svgList.Dashboard]: dashboard,
  [svgList.Recording]: recording,
  [svgList.Callback]: callback,
  [svgList.Settings]: settings,
};
