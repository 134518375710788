import classNames from "classnames";
import { useContext } from "react";

import { ReactComponent as ArrowDropdown } from "icons/arrowDropdown.svg";

import {
  HeaderApiContext,
  HeaderStateContext,
} from "../../../../../../contexts/header-context";
import { LinksGroup } from "../../../links-group";

import styles from "./links-dropdown.module.scss";
import { Props } from "./links-dropdown.types";

export function LinksDropdown({
  id,
  title,
  linkGroups,
  isOneColumn = false,
}: Props): JSX.Element {
  const { openedMenuGroupId, isTransparent } = useContext(HeaderStateContext);
  const { openMenuGroup, closeMenuGroup } = useContext(HeaderApiContext);

  const isExpanded = id === openedMenuGroupId;
  const linksId = `${id}-links`;
  const isGroupTitleHidden = linkGroups.length === 1;

  const mouseOverHandler = () => {
    openMenuGroup(id, true);
  };

  const mouseOutHandler = () => {
    closeMenuGroup(true);
  };

  const clickHandler = () => {
    if (isExpanded) {
      closeMenuGroup(true);

      return;
    }

    openMenuGroup(id, true);
  };

  return (
    <div
      className={classNames(styles.dropdown, {
        [styles.whiteDropdown]: isTransparent,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <button
        className={classNames(styles.button, "h3")}
        type="button"
        onMouseOver={mouseOverHandler}
        onClick={clickHandler}
        aria-expanded={isExpanded}
        aria-controls={linksId}
      >
        {title}

        <ArrowDropdown className={styles.icon} aria-hidden />
      </button>

      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        id={linksId}
        className={classNames(styles.linksGroupWrapper, {
          [styles.hiddenLinksGroupWrapper]: !isExpanded,
        })}
        onMouseOver={mouseOverHandler}
        onMouseOut={mouseOutHandler}
      >
        <LinksGroup
          className={classNames(styles.linksGroup, {
            [styles.smallLinksGroup]: isOneColumn,
          })}
          groups={linkGroups}
          isGroupTitleHidden={isGroupTitleHidden}
          isOneColumn={isOneColumn}
        />
      </div>
    </div>
  );
}
