import { MenuLink, MenuLinkGroup } from "./header.types";

export const MENU_ITEMS: (MenuLinkGroup | MenuLink)[] = [
  {
    id: "main",
    title: "О нас",
    href: "/about",
  },
  {
    id: "prices",
    title: "Платные услуги",
    href: "/prices",
  },
  {
    id: "request",
    title: "Оставить заявку",
    href: "/request",
  },
  {
    id: "contacts",
    title: "Контакты",
    href: "/contacts",
  },
  {
    id: "docs",
    title: "Cертификаты",
    href: "/documents",
  },
];

export const NAVIGATION_ID = "main-navigation";
