import classNames from "classnames";

import { useContext } from "react";

import { HeaderStateContext } from "../../../../contexts/header-context";

import styles from "./menu-link.module.scss";
import { Props } from "./menu-link.types";

export function MenuLink({ title, href, className = "" }: Props): JSX.Element {
  const { isTransparent } = useContext(HeaderStateContext);

  return (
    <a
      href={href}
      className={classNames("h3", className, styles.link, {
        [styles.whiteLink]: isTransparent,
      })}
    >
      {title}
    </a>
  );
}
