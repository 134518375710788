import classNames from 'classnames';

import style from './divider-item.module.scss';

export type DividerItemProps = {
  description: string | JSX.Element;
  title: string;
  variant?: 'white' | 'basic' | 'small' | 'main';
  divider?: boolean;
  graphikLc?: boolean;
  titleClassName?: string;
};

export type DividerItemWithLinkProps = {
  description: string | JSX.Element;
  title: string;
  link?: JSX.Element | null;
  subtitle?: string;
  noDivider?: boolean;
  icon?: JSX.Element | null;
};

function Base({
  description,
  title,
  titleClassName = '',
  variant = 'basic',
  divider = false,
  graphikLc = false,
}: DividerItemProps): JSX.Element {
  return (
    <div
      className={classNames({
        [style.wrapper]: divider,
        [style.base]: variant === 'basic',
        [style.white]: variant === 'white',
        [style.small]: variant === 'small',
        [style.dividerBlock]: variant === 'main',
        [style.graphikLcBase]: graphikLc,
      })}
    >
      <div className={titleClassName || style.title}>{title}</div>
      <p className={style.description}>{description}</p>
    </div>
  );
}

function WithLink({
  link = null,
  title,
  description,
  subtitle = '',
  noDivider = false,
  icon = null,
}: DividerItemWithLinkProps) {
  return (
    <div className={classNames(style.wrapper, style.withLink, { [style.noDivider]: noDivider })}>
      {icon && <div className={style.icon}>{icon}</div>}
      {subtitle && <div className={style.subtitle}>{subtitle}</div>}
      <div className={style.title}>{title}</div>
      <p className={style.description}>{description}</p>
      {link && <div className={style.linkWrapper}>{link}</div>}
    </div>
  );
}

export const DividerItem = {
  Base,
  WithLink,
};
