import { ValuesOf } from "../../../../../../shared/config";

export const colorList = {
  ORANGE: "orange",
  GREEN: "green",
  PURPLE: "purple",
} as const;

export type ValuesOfColorList = ValuesOf<typeof colorList>;

export type Props = {
  text: string;
  color: ValuesOfColorList;
  classname?: string;
};
