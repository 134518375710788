import { useEffect, useRef, useCallback, MutableRefObject } from 'react';

import styleLayout from '../../ui/layout/layout.module.scss';
import { useBlockScrolling } from '../use-block-scrolling';

type Props = {
  isBlocking: boolean;
  setIsBlocking: (value: boolean) => void;
  classnamePopupContent: string;
};

export function usePopup({ isBlocking, setIsBlocking, classnamePopupContent }: Props): MutableRefObject<number | null> {
  const headerPage = useRef<HTMLElement | null>(null);
  const layoutPage = useRef<HTMLElement | null>(null);
  const scrollWidth = useRef<number | null>(null);
  const timeoutId = useRef<number | null>(null);

  const onDeleteByKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && setIsBlocking) setIsBlocking(false);
    },
    [setIsBlocking],
  );

  const onDeleteByClick = useCallback(
    (event: MouseEvent) => {
      const targetEl = event.target as HTMLElement;
      if (!targetEl?.closest(`.${classnamePopupContent}`)) setIsBlocking(false);
    },
    [setIsBlocking],
  );

  useBlockScrolling(isBlocking);

  useEffect(() => {
    headerPage.current = document.querySelector('header');
    layoutPage.current = document.querySelector(`.${styleLayout.content}`);
    scrollWidth.current = window.innerWidth - document.body.clientWidth;
  }, []);

  useEffect(() => {
    if (layoutPage.current) {
      layoutPage.current.style.paddingRight = isBlocking ? `${scrollWidth.current}px` : '0';
    }

    if (headerPage.current) {
      headerPage.current.style.paddingRight = isBlocking ? `${scrollWidth.current}px` : '0';
      headerPage.current.style.backgroundColor = isBlocking ? '#fcfcfc' : '';
    }
  }, [isBlocking]);

  useEffect(() => {
    if (isBlocking) {
      timeoutId.current = window.setTimeout(() => {
        document.addEventListener('click', onDeleteByClick);
        document.addEventListener('keydown', onDeleteByKeydown);
      }, 1000);
    }

    return () => {
      document.removeEventListener('click', onDeleteByClick);
      document.removeEventListener('keydown', onDeleteByKeydown);
    };
  }, [isBlocking, onDeleteByClick, onDeleteByKeydown]);

  return timeoutId;
}
