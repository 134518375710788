import classNames from "classnames";
import { useRef } from "react";

import { Header } from "./components/header";
import { HeaderContextProvider } from "./contexts/header-context";
import { Props } from "./layout.config";
import styles from "./layout.module.scss";

export function Layout({
  children,
  headerOptions: { isTransparent = false, isFixed = false, withMenu = true } = {
    isTransparent: false,
    isFixed: false,
    withMenu: true,
  },
}: Props): JSX.Element {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <HeaderContextProvider
        contentRef={contentRef}
        isTransparent={isTransparent}
      >
        <Header
          withMenu={withMenu}
          className={classNames(styles.header, {
            [styles.fixedHeader]: isFixed || isTransparent,
          })}
        />
      </HeaderContextProvider>

      <div className={styles.content} ref={contentRef}>
        <main>{children}</main>
      </div>
    </>
  );
}
