import { Layout, OrdinarySectionProps } from "../../shared/ui";
import { Cards } from "./components/cards/cards";
import { convertTitleCard } from "../../shared/lib/convert-card";
import Sert1 from "../../images/s1.jpg";
import Sert2 from "../../images/s2.jpg";
import Sert3 from "../../images/s3.jpg";
import Sert4 from "../../images/s4.jpg";
import Sert5 from "../../images/s5.jpg";
import Sert6 from "../../images/s6.jpg";

const cards = [
  {
    titleText: convertTitleCard(
      "ООО «Мастер Тек» имеет ***Сертификат Соответствия*** системы добровольной сертификации «МОСЖКХ»"
    ),
    img: { src: Sert1, width: 2400, height: 3400 },
  },
  {
    titleText: "",
    description:
      "Сертификат удостоверяет, что жилищно-коммунальные улуги, оказываемые ООО «Мастер Тек» соответствуют требованиям",
    img: { src: Sert2, width: 2400, height: 3400 },
  },
  {
    titleText: "",
    description: "Перечень сертифицированных услуг",
    img: { src: Sert3, width: 2400, height: 3400 },
  },
  {
    titleText: "",
    description: "Перечень сертифицированных услуг",
    img: { src: Sert4, width: 2400, height: 3400 },
  },
  {
    titleText: "",
    description: "Перечень сертифицированных услуг",
    img: { src: Sert5, width: 2400, height: 3400 },
  },
  {
    titleText: convertTitleCard(
      "ООО «Мастер Тек» имеет ***Сертификат*** по организации клинингового бизнеса"
    ),
    img: { src: Sert6, width: 2400, height: 3400 },
  },
];

export function Documents(): JSX.Element {
  return (
    <Layout>
      <Cards cards={cards} title="Наши сертификаты" />
    </Layout>
  );
}
