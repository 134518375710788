import { Image } from "../../../shared/ui";
const IMAGE_BASE_URL = "/images/pages/new/keep-all-communication";

const whatsAppImgName = "whatsapp";
const telegramImgName = "telegram";
const webchatImgName = "webchat";
const instagramImgName = "instagram";

const whatsappImgData = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${whatsAppImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${whatsAppImgName}-sm@2x.webp`,
    width: "280",
    height: "164",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${whatsAppImgName}-md@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${whatsAppImgName}-md@2x.webp`,
    width: "344",
    height: "178",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${whatsAppImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${whatsAppImgName}-lg@2x.webp`,
    width: "560",
    height: "328",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${whatsAppImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${whatsAppImgName}-lg@2x.webp`,
    width: "800",
    height: "343",
  },
  alt: "Whatsapp chat",
};

const telegramImgData = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${telegramImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${telegramImgName}-sm@2x.webp`,
    width: "280",
    height: "164",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${telegramImgName}-md@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${telegramImgName}-md@2x.webp`,
    width: "344",
    height: "178",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${telegramImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${telegramImgName}-lg@2x.webp`,
    width: "560",
    height: "328",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${telegramImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${telegramImgName}-lg@2x.webp`,
    width: "800",
    height: "343",
  },
  alt: "Telegram chat",
};

const webchatImgData = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${webchatImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${webchatImgName}-sm@2x.webp`,
    width: "280",
    height: "164",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${webchatImgName}-md@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${webchatImgName}-md@2x.webp`,
    width: "344",
    height: "178",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${webchatImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${webchatImgName}-lg@2x.webp`,
    width: "560",
    height: "328",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${webchatImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${webchatImgName}-lg@2x.webp`,
    width: "800",
    height: "343",
  },
  alt: "Webchat chat",
};

const instagramImgData = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${instagramImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${instagramImgName}-sm@2x.webp`,
    width: "280",
    height: "164",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${instagramImgName}-md@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${instagramImgName}-md@2x.webp`,
    width: "344",
    height: "178",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${instagramImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${instagramImgName}-lg@2x.webp`,
    width: "560",
    height: "328",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${instagramImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${instagramImgName}-lg@2x.webp`,
    width: "800",
    height: "343",
  },
  alt: "Instagram chat",
};

export const imagesData: Image[] = [
  whatsappImgData,
  telegramImgData,
  webchatImgData,
  instagramImgData,
];

export const iconsNames: string[] = [
  whatsAppImgName,
  telegramImgName,
  webchatImgName,
  instagramImgName,
];
