import { FormEvent, InvalidEvent } from "react";

import { ValuesOf } from "shared/config";

type Key = string | number | symbol;

export type FormElement = HTMLInputElement | HTMLTextAreaElement;

export type InvalidEventHandler = (event: InvalidEvent<FormElement>) => void;

type Option =
  | {
      message: string;
      mode: ValuesOfValidationMode;
    }
  | false;

type ValidationResult = string | null;

// TODO: Кажется надо описать 2 разны функции для value - string и value - boolean
type ValidateFunction = (
  value: string | boolean
) => ValidationResult | Promise<ValidationResult>;

type CustomValidation = {
  validate: ValidateFunction;
  mode?: ValuesOfValidationMode;
};

type Options = {
  builtInValidations?: {
    isRequired?: Option;
    isValueMatchType?: Option;
  };
  customValidations?: CustomValidation[];
};

export type Register<FieldName extends Key> = (
  name: FieldName,
  options?: Options
) => {
  ref: (node: FormElement | null) => void;
  name: FieldName;
  required: boolean;
};

export type Errors<FieldName extends Key> = Partial<
  Record<FieldName, string[] | null>
>;

export const ValidationSource = {
  BLUR: "blur",
  SUBMIT: "submit",
} as const;

export type ValuesOfValidationSource = ValuesOf<typeof ValidationSource>;

export const ValidationMode = {
  ON_SUBMIT: "onSubmit",
  ON_BLUR_AND_SUBMIT: "onBlurAndSubmit",
} as const;

type ValuesOfValidationMode = ValuesOf<typeof ValidationMode>;

export type FieldsMap<FieldName extends Key> = Map<
  FieldName,
  {
    ref: FormElement;
    validate: (value: string | boolean) => Promise<boolean> | boolean;
  }
>;

export const State = {
  IDLE: "IDLE",
  VALIDATING: "VALIDATING",
} as const;

export type ValuesOfState = ValuesOf<typeof State>;

export type FieldsState<FieldName extends Key> = Partial<
  Record<FieldName, ValuesOfState>
>;

export type SubmitHandler<Fields extends Record<string, string | boolean>> = (
  values: Fields,
  event: FormEvent<HTMLFormElement>
) => void;

export const DEFAULT_IS_REQUIRED_OPTION: Option = {
  message: "This field is required",
  mode: ValidationMode.ON_SUBMIT,
};

export const DEFAULT_OPTIONS: Options = {
  builtInValidations: {
    isRequired: DEFAULT_IS_REQUIRED_OPTION,
  },
};
