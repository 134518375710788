const IMAGE_BASE_URL = '/images/pages/careers';
const SECTION_NAME = 'about-voiso';

export const ABOUT_VOISO_IMAGE = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${SECTION_NAME}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${SECTION_NAME}-sm@2x.webp`,
    width: '287',
    height: '132',
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${SECTION_NAME}-md@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${SECTION_NAME}-md@2x.webp`,
    width: '640',
    height: '295',
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${SECTION_NAME}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${SECTION_NAME}-lg@2x.webp`,
    width: '757',
    height: '349',
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${SECTION_NAME}-xl@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${SECTION_NAME}-xl@2x.webp`,
    width: '803',
    height: '370',
  },
  alt: 'Managers works in Voiso office',
};
