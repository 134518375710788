import classNames from "classnames";

import style from "./measure-and-control.module.scss";
import {
  DefinitionListItemProps,
  QuoteCardProps,
  SecondarySection,
  DefinitionList,
  ContentImage,
  QuoteCard,
  Image,
} from "../../../../shared/ui";

export type Props = {
  subTitle: string;
  description: string;
  featuresItems: DefinitionListItemProps[];
  img: Image;

  clientsReviewTitle: string;
  quotes: QuoteCardProps[];
};

export function MeasureAndControl({
  subTitle,
  description,
  featuresItems,
  img,
}: Props): JSX.Element {
  return (
    <SecondarySection isVerticalPadding64>
      <div className={style.grid}>
        <div className={style.gridWrapper}>
          <p className={classNames("h3", style.subtitle)}>{subTitle}</p>
          <p className={classNames("body-text", style.description)}>
            {description}
          </p>
          <DefinitionList classname={style.card} items={featuresItems} />
          <ContentImage image={img} className={style.image} />
        </div>
      </div>
    </SecondarySection>
  );
}
