import { ValuesOf } from "shared/config";

import { SvgNameList } from "../../../icon";
import { LazyNextContentImageProps } from "../../imgs/lazy-next-content-image";

export const ColorGradientList = {
  ORANGE: "orange",
  PINK: "pink",
  BLUE: "blue",
} as const;

export type ValuesOfColorGradients = ValuesOf<typeof ColorGradientList>;

export type TitleText = {
  value: string;
  isGradient: boolean;
};

export type PropsWrapper = {
  titleText: TitleText[] | string;
  link?: {
    text: string;
    href: string;
  };
  classname?: string;
  description?: string;
  children: React.ReactNode;
};

export type Props<X> = Omit<PropsWrapper, "children"> & X;

export type ColorTextProps = {
  coloredText: {
    value: string;
    color: ValuesOfColorGradients;
  };
};

export type ImagePropsUC = {
  img: LazyNextContentImageProps;
};

export type SVGPropsUC = {
  svg: {
    svgName: SvgNameList;
    className?: string;
  };
};

export const isColorText = (
  props: ColorTextProps | ImagePropsUC | SVGPropsUC
): props is ColorTextProps => "coloredText" in props;
export const isImage = (
  props: ColorTextProps | ImagePropsUC | SVGPropsUC
): props is ImagePropsUC => "img" in props;
export const isSvg = (
  props: ColorTextProps | ImagePropsUC | SVGPropsUC
): props is SVGPropsUC => "svg" in props;
