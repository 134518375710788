import classNames from "classnames";

import style from "./row.module.scss";

export function Row({ title, unit, price }: any): JSX.Element {
  return (
    <tr key={title}>
      <td className={classNames(style.cell, style.titleCell)}>
        <div className={classNames(style.cellContent, style.titleContent)}>
          {title}
        </div>
      </td>
      <td className={classNames(style.cell, style.cityCell)}>
        <div className={style.cellContent}>{unit}</div>
      </td>
      <td className={classNames(style.cell, style.datesCell)}>
        <div className={classNames(style.cellContent, style.datesContent)}>
          {price}
        </div>
      </td>
    </tr>
  );
}
