import classNames from "classnames";
import { useState } from "react";

import style from "./lazy-next-content-image.module.scss";

export type Props = {
  src: string;
  title: string;
  alt: string;
  width: number;
  height: number;
  classname?: string;
};

export function LazyNextContentImage({
  src,
  title,
  alt,
  classname = "",
  width,
  height,
}: Props): JSX.Element {
  const [isLoading, setLoading] = useState(true);

  return (
    <div
      className={classNames(
        style.wrapper,
        { [style.loading]: isLoading },
        classname
      )}
    >
      <img
        src={src}
        alt={alt}
        title={title}
        className={style.img}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
}
