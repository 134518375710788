import classNames from "classnames";
import { FC, ReactNode } from "react";

import { ImageConfig } from "shared/config";
import {
  defineViewPort,
  useViewport,
  useWindowSize,
  ViewPort,
} from "shared/lib/dom";
import { Button } from "shared/ui/button";
import { Container } from "shared/ui/container";
import { DeprecatedPicture } from "shared/ui/deprecated-picture";
import { Header } from "shared/ui/headers";
import { LazyLoadingBackgroundImage } from "shared/ui/lazy-loading-background-image";

import style from "./style.module.scss";

export type DefaultPrimarySectionProps = {
  imageUrl?: string;
  mdImageUrl?: string;
  smImageUrl?: string;
  xlImageUrl?: string;
  title: JSX.Element | string;
  subTitle: string | JSX.Element;
  description?: string;
  imageContent?: JSX.Element;
  hideButton?: boolean;
  fullScreen?: boolean;
  alt?: string;
};

export type ImagePrimarySectionProps = {
  imageUrl: string;
  // eslint-disable-next-line react/no-unused-prop-types
  mdImageUrl?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  lgImageUrl?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  smImageUrl?: string;
  title: string;
  subTitle?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  isLocalLink?: boolean;
  objectPosition?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  showSubmitBtn?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  isFullHeight?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: ReactNode;
};

type LazyBGImageProps = Omit<ImagePrimarySectionProps, "imageUrl"> & {
  className: string;
};

type VideoPrimarySectionProps = {
  videoUrl: string;
  title: JSX.Element | string;
  subTitle: string;
  // eslint-disable-next-line react/no-unused-prop-types
  rightContent?: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  poster?: string;
};

const imageSizes = {
  [ViewPort.Xl]: { width: 2400, height: 645 },
  [ViewPort.Lg]: { width: 1280, height: 328 },
  [ViewPort.Md]: { width: 768, height: 352 },
  [ViewPort.Sm]: { width: 375, height: 333 },
};

function Default({
  imageUrl,
  mdImageUrl,
  smImageUrl,
  xlImageUrl,
  subTitle,
  title,
  hideButton,
  imageContent,
  fullScreen = false,
  description,
  alt = "",
}: DefaultPrimarySectionProps): JSX.Element {
  const windowWidth = useWindowSize().width;
  const pictureSizes = imageSizes[defineViewPort(windowWidth)];

  let pictureSrc = imageUrl;

  if (smImageUrl && windowWidth < ViewPort.Md) {
    pictureSrc = smImageUrl;
  } else if (mdImageUrl && windowWidth < ViewPort.Lg) {
    pictureSrc = mdImageUrl;
  } else if (xlImageUrl && windowWidth > ViewPort.Xl) {
    pictureSrc = xlImageUrl;
  }

  return (
    <div
      className={classNames(style.primarySectionWrapper, {
        [style.hideButton]: hideButton,
        [style.fullScreen]: fullScreen,
      })}
    >
      <Container>
        <div className={style.headersWrapper}>
          <div className={style.mainHeader}>
            <Header.H2 color="black" text={title} />
            {!hideButton && (
              <Button.Secondary
                text="Start a free trial"
                href="/start-a-free-trial"
              />
            )}
          </div>
          <div className={style.subHeaderWrapper}>
            <Header.H2
              text={subTitle}
              className={style.subHeader}
              color="grey"
            />
            {description && <p className={style.description}>{description}</p>}
          </div>
        </div>
      </Container>
      <div className={style.imageWrapper}>
        {pictureSrc && (
          <DeprecatedPicture
            src={pictureSrc}
            layout="responsive"
            height={pictureSizes.height}
            width={pictureSizes.width}
            objectFit="cover"
            quality={ImageConfig.images.quality}
            alt={alt}
          />
        )}
        {imageContent && (
          <div className={style.imageContent}>{imageContent}</div>
        )}
      </div>
    </div>
  );
}

const ImageLongStoryBG: FC<ImagePrimarySectionProps> = ({
  imageUrl,
  children,
  title,
  subTitle,
  objectPosition,
  showSubmitBtn,
  className,
}) => (
  <div
    className={classNames(
      style.wrapper,
      style.primarySectionWrapper,
      style.verticalCentered,
      className,
      {
        [style.wrapperWithChildren]: children,
      }
    )}
  >
    <div
      className={classNames(style.content, {
        [style.contentHeight]: !children,
      })}
    >
      <Container
        className={classNames(style.contentLongStoryCase, {
          [style.contentLongWithChildren]: children,
        })}
      >
        <Header.H1
          color="white"
          className={classNames(style.storyTitle, style.wideTitle)}
          text={title}
          subheader={subTitle}
        />

        {showSubmitBtn && (
          <Button.Primary
            className={style.button}
            color="blue"
            text="Request a demo"
            href="#request-a-demo"
            isFullWidth
          />
        )}
      </Container>
      {children}
    </div>
    <DeprecatedPicture
      objectFit="cover"
      layout="fill"
      src={imageUrl}
      quality={100}
      objectPosition={objectPosition}
      alt=""
    />
  </div>
);

const ImageBG: FC<ImagePrimarySectionProps> = ({
  imageUrl,
  title,
  subTitle = undefined,
  isLocalLink = false,
  objectPosition = undefined,
  mdImageUrl = undefined,
  lgImageUrl = undefined,
  smImageUrl = undefined,
  isFullHeight = false,
}) => {
  const pictureSrc = useViewport({
    [ViewPort.Sm]: smImageUrl || imageUrl,
    [ViewPort.Md]: mdImageUrl || imageUrl,
    [ViewPort.Lg]: lgImageUrl || imageUrl,
    [ViewPort.Xl]: imageUrl,
  });

  return (
    <div
      className={classNames(
        style.wrapper,
        style.imageBgWrapper,
        style.primarySectionWrapper
      )}
    >
      <div
        className={classNames(style.content, {
          [style.fullHeight]: isFullHeight,
        })}
      >
        <Container
          className={classNames(style.contentCase, {
            [style.fullHeight]: isFullHeight,
          })}
        >
          <Header.H1
            color="white"
            className={classNames(style.header, style.imageBgHeader)}
            text={title}
            topSubheader
            subheader={subTitle}
          />
          <Button.Primary
            className={style.button}
            color="white"
            text="Request a demo"
            href={isLocalLink ? "#request-a-demo" : "/request-a-demo"}
            isFullWidth
          />
        </Container>
      </div>

      <DeprecatedPicture
        objectFit="cover"
        layout="fill"
        src={pictureSrc}
        quality={75}
        objectPosition={objectPosition}
        alt=""
      />
    </div>
  );
};

const VideoBG: FC<VideoPrimarySectionProps> = ({
  videoUrl,
  title,
  subTitle,
  poster,
}) => (
  <div
    className={classNames(
      style.wrapper,
      style.primarySectionWrapper,
      style.dark
    )}
  >
    <div className={classNames(style.content, style.h100)}>
      <Container className={classNames(style.h100, style.videoContentCase)}>
        <Header.H1
          color="white"
          className={style.header}
          text={title}
          topSubheader
          subheader={subTitle}
        />
        <div className={style.buttonContainer}>
          <Button.Primary
            className={style.button}
            color="white"
            text="Request a demo"
            href="/request-a-demo"
          />
          <Button.Primary
            className={classNames(style.button, style.transparent)}
            text="Start a free trial"
            href="/start-a-free-trial"
            color="blue"
            withoutBackground
          />
        </div>
      </Container>
    </div>
  </div>
);

export function LazyBGImage({
  title,
  subTitle = undefined,
  isLocalLink = false,
  isFullHeight = false,
  className,
}: LazyBGImageProps) {
  return (
    <LazyLoadingBackgroundImage
      className={classNames(
        className,
        style.wrapper,
        style.imageBgWrapper,
        style.primarySectionWrapper
      )}
    >
      <div
        className={classNames(style.content, {
          [style.fullHeight]: isFullHeight,
        })}
      >
        <Container
          className={classNames(style.contentCase, {
            [style.fullHeight]: isFullHeight,
          })}
        >
          <Header.H1
            color="white"
            className={classNames(style.header, style.imageBgHeader)}
            text={title}
            topSubheader
            subheader={subTitle}
          />
          <Button.Primary
            className={style.button}
            color="white"
            text="Request a demo"
            href={isLocalLink ? "#request-a-demo" : "/request-a-demo"}
            isFullWidth
          />
        </Container>
      </div>
    </LazyLoadingBackgroundImage>
  );
}

export const PrimarySection = {
  Default,
  ImageBG,
  ImageLongStoryBG,
  VideoBG,
};

export { PrimarySectionLazyDefault } from "./lazy-default";
export { LazyPrimarySectionImageLongStoryBG } from "./lazy-image-long-story-bg";
export { LazyPrimarySectionImageBG } from "./lazy-image-bg";
