import { ValuesOf } from "shared/config";

import style from "./button-carousel.module.scss";

export const DividerSize = {
  S: "S",
  M: "M",
} as const;

export type ValuesOfDividerSize = ValuesOf<typeof DividerSize>;

export const DividerSizeToClassName: Record<ValuesOfDividerSize, string> = {
  [DividerSize.S]: style.dividerS,
  [DividerSize.M]: style.dividerM,
};
