import classNames from 'classnames';

import { Icon, SvgNameList } from '../../../icon';

import style from './definition-list.module.scss';

export type ItemProps = {
  featureTitle: string;
  description: string;
  svgName?: SvgNameList;
};

export type Props = {
  items: ItemProps[];
  classname?: string;
};

export function DefinitionList({ items, classname = '' }: Props) {
  return (
    <dl className={classNames('body-text', style.featuresBlock, classname)}>
      {items.map(({ featureTitle, description, svgName }) => (
        <div key={featureTitle} className={classNames({ [style.featureItem]: !!svgName })}>
          <dt className={style.title}>
            {svgName && <Icon name={svgName} className={style.svg} aria-hidden />}
            {featureTitle}
          </dt>
          <dd className={style.description}>{description}</dd>
        </div>
      ))}
    </dl>
  );
}
