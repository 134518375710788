import classNames from "classnames";

import { MENU_ITEMS } from "../../header.constants";
import { isMenuLinkGroup } from "../../header.utils";
import { MenuLink } from "../menu-link";

import { LinksAccordion } from "./components/links-accordion";
import styles from "./menu-with-accordions.module.scss";
import { Props } from "./menu-with-accordions.types";

export function MenuWithAccordions({ className }: Props): JSX.Element {
  return (
    <div className={classNames(className, styles.menu)}>
      <ul className={styles.list}>
        {MENU_ITEMS.map((menuItem) => {
          const { id, title } = menuItem;

          if (isMenuLinkGroup(menuItem)) {
            const { linkGroups, isOneColumn } = menuItem;

            return (
              <li key={id} className={styles.listItem}>
                <LinksAccordion
                  id={id}
                  title={title}
                  linkGroups={linkGroups}
                  isOneColumn={isOneColumn}
                />
              </li>
            );
          }

          return (
            <li key={id} className={styles.listItem}>
              <MenuLink title={title} href={menuItem.href} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
