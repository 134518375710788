import classNames from "classnames";

import { MENU_ITEMS } from "../../header.constants";
import { isMenuLinkGroup } from "../../header.utils";
import { MenuLink } from "../menu-link";

import { LinksDropdown } from "./components/links-dropdown";
import styles from "./menu-with-dropdowns.module.scss";
import { Props } from "./menu-with-dropdowns.types";

export function MenuWithDropdowns({ className }: Props): JSX.Element {
  return (
    <ul className={classNames(className, styles.links)}>
      {MENU_ITEMS.map((menuItem) => {
        const { id, title } = menuItem;

        if (isMenuLinkGroup(menuItem)) {
          const { linkGroups, isOneColumn } = menuItem;

          return (
            <li key={id}>
              <LinksDropdown
                id={id}
                title={title}
                linkGroups={linkGroups}
                isOneColumn={isOneColumn}
              />
            </li>
          );
        }

        return (
          <li key={id}>
            <MenuLink
              className={styles.menuLink}
              title={title}
              href={menuItem.href}
            />
          </li>
        );
      })}
    </ul>
  );
}
