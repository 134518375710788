import classNames from "classnames";

import { Grid, SecondarySection, UiCard, UiCardProps } from "shared/ui";

import style from "./cards.module.scss";

export type Props = {
  title: string;
  cards: UiCardProps<any>[];
};

export function Cards({ title, cards }: Props): JSX.Element {
  return (
    <SecondarySection isVerticalPadding64>
      <Grid className={style.grid}>
        <h2 className={classNames("h2", style.title)}> {title} </h2>

        <div className={style.wrapperCard}>
          {cards.map((card) => {
            const cardProps = { ...card, className: style.video };
            return <UiCard key={card.description} {...cardProps} />;
          })}
        </div>
      </Grid>
    </SecondarySection>
  );
}
