import { TitleTextProps } from '../../ui/ui-kit/cards/univarsal-card';

export const convertTitleCard = (textContent: string): TitleTextProps[] => {
  const textForRender = textContent.split(/\*{3}/).filter((text: string) => Boolean(text));

  const isOdd = textContent.startsWith('***');

  return textForRender.map((text: string, index: number) => {
    if (!isOdd) {
      return {
        isGradient: Boolean(index % 2),
        value: text,
      };
    }

    return {
      isGradient: !(index % 2),
      value: text,
    };
  });
};
