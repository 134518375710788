import { Api, State } from './header-context.types';

export const DEFAULT_STATE: State = {
  isMenuOpened: false,
  isTransparent: false,
  openedMenuGroupId: null,
};

function emptyFunction() {}

export const DEFAULT_API: Api = {
  toggleMenu: emptyFunction,
  openMenuGroup: emptyFunction,
  closeMenuGroup: emptyFunction,
};

export const ESCAPE_KEY_CODE = 'Escape';
export const SCROLL_THRESHOLD_FOR_TRANSPARENT_MODE_IN_PX = 30;
