import { useEffect, useState } from 'react';

export function useWindowWidthResize(onResize: () => void): void {
  const [widthWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const resizeHandler = (event: Event) => {
      const newWidth = (event.target as Window).innerWidth;

      if (newWidth === widthWidth) {
        return;
      }

      onResize();
      setWindowWidth(newWidth);
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [onResize, widthWidth]);
}
