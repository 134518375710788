import { NewForm } from "../../new-form";
import { Layout } from "../../shared/ui";

export const Request = () => {
  return (
    <Layout>
      <NewForm
        ordinaryTitle="Заявка на оказание услуг"
        description="Опишите необходимые вам услуги, мы с вами свяжемся"
        buttonText="Отправить"
        formName="request-a-demo"
      />
    </Layout>
  );
};
