import { useEffect, useState } from 'react';

export enum ViewPort {
  Sm = 375,
  Md = 768,
  Lg = 1280,
  Xl = 2400,
}

export const defineViewPort = (width: number): ViewPort => {
  if (width < ViewPort.Md) {
    return ViewPort.Sm;
  }

  if (width < ViewPort.Lg) {
    return ViewPort.Md;
  }

  if (width < ViewPort.Xl) {
    return ViewPort.Lg;
  }

  return ViewPort.Xl;
};

export const useWindowSize = (): { width: number; height: number } => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const getCurrentViewport = (width: number) => {
  if (width < ViewPort.Md) {
    return ViewPort.Sm;
  }

  if (width < ViewPort.Lg) {
    return ViewPort.Md;
  }

  if (width < ViewPort.Xl) {
    return ViewPort.Lg;
  }

  return ViewPort.Xl;
};

export const useViewport = <T>(map: Record<number, T>): T => {
  const { width } = useWindowSize();
  const [state, setState] = useState<T>(map[getCurrentViewport(width)]);
  const viewPort = getCurrentViewport(width);

  useEffect(() => {
    setState(map[viewPort] || state);
  }, [viewPort]);

  return state;
};
