import cn from 'classnames';
import { ReactNode } from 'react';

import { Header } from '../headers';

import style from './secondary-section.module.scss';

type Props = {
  id?: string;
  children: ReactNode;
  header?: string;
  headerBlur?: boolean;
  fullHeight?: boolean;
  className?: string;
  titleClass?: string;
  isVerticalPadding64?: boolean;
};

export function SecondarySection({
  children,
  id = undefined,
  header = '',
  className = '',
  fullHeight = false,
  headerBlur = false,
  titleClass = '',
  isVerticalPadding64 = false,
}: Props): JSX.Element {
  return (
    <section
      className={cn(
        style.secondary,
        className,
        { [style.fullHeight]: fullHeight },
        { [style.blur]: headerBlur },
        { [style.p64]: isVerticalPadding64 },
      )}
      id={id}
    >
      {header && <Header.H2 className={cn(style.header)} titleClass={titleClass} text={header} color="black" />}

      {children}
    </section>
  );
}
