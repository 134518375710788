import {
  MainProps,
  OurValuesProps,
  PerksAndBenefitsProps,
  AboutVoisoProps,
  JoinVoiso,
  JoinVoisoProps,
} from "./components";
import { SvgName, Layout, ContentImage } from "../../shared/ui";

export type Props = {
  main: MainProps;
  ourValues: OurValuesProps;
  perksAndBenefits: PerksAndBenefitsProps;
  aboutVoiso: AboutVoisoProps;
  joinVoiso: JoinVoisoProps;
};

const IMAGE_BASE_URL = "/images/pages/careers";

const IMG = {
  mobile: {
    retina: `${IMAGE_BASE_URL}/doma.jpeg`,
    normal: `${IMAGE_BASE_URL}/doma.jpeg`,
    width: "303",
    height: "210",
  },
  tablet: {
    retina: `${IMAGE_BASE_URL}/doma.jpeg`,
    normal: `${IMAGE_BASE_URL}/doma.jpeg`,
    width: "377",
    height: "210",
  },
  desktop: {
    retina: `${IMAGE_BASE_URL}/doma.jpeg`,
    normal: `${IMAGE_BASE_URL}/doma.jpeg`,
    width: "661",
    height: "324",
  },
  desktopXl: {
    retina: `${IMAGE_BASE_URL}/doma.jpeg`,
    normal: `${IMAGE_BASE_URL}/doma.jpeg`,
    width: "626",
    height: "324",
  },
  alt: "Команда профессионалов",
};

const props = {
  joinVoiso: {
    title: "Наши контакты",
    vacancies: [
      {
        title: (
          <>
            Общество с ограниченной ответственностью
            <br />
            «Мастер&nbsp;Тек» (ООО&nbsp;«Мастер&nbsp;Тек»)
          </>
        ),
        conditionsBlock: [
          {
            text: "Юридический адрес: 111024, г. Москва, ул. Авиамоторная, д. 49/1, пом. 11",
            svg: {
              svgName: SvgName.LOCATION,
              width: 32,
              height: 32,
            },
          },
          {
            text: "ИНН 7722695700",
            svg: {
              svgName: SvgName.DOCUMENTATION,
              width: 32,
              height: 32,
            },
          },
          {
            text: "ОГРН 1097746558370",
            svg: {
              svgName: SvgName.DOCUMENTATION,
              width: 32,
              height: 32,
            },
          },
          {
            text: "Телефон организации: 8-925-230-34-39",
            svg: {
              svgName: SvgName.SOFT_PHONE,
              width: 32,
              height: 32,
            },
          },
          {
            text: "E-mail: infomtek@bk.ru",
            svg: {
              svgName: SvgName.MAIL_WAVE,
              width: 32,
              height: 32,
            },
          },
        ],
      },
    ],
  },
};

export function Careers(): JSX.Element {
  const { joinVoiso } = props;

  return (
    <Layout>
      {joinVoiso.vacancies.length > 0 && <JoinVoiso {...joinVoiso} />}
      <ContentImage image={IMG} />
    </Layout>
  );
}
