import { useCallback, useEffect, useMemo, useState } from "react";

import { useBlockScrolling } from "shared/lib/use-block-scrolling";

import { HeaderApiContext } from "./header-api.context";
import {
  DEFAULT_STATE,
  ESCAPE_KEY_CODE,
  SCROLL_THRESHOLD_FOR_TRANSPARENT_MODE_IN_PX,
} from "./header-context.constants";
import { Api, ProviderProps, State } from "./header-context.types";
import { HeaderStateContext } from "./header-state.context";
import { useWindowWidthResize } from "./hooks/use-window-width-resize";

function isPageNotScrolled(): boolean {
  return window.scrollY <= SCROLL_THRESHOLD_FOR_TRANSPARENT_MODE_IN_PX;
}

export function HeaderContextProvider({
  children,
  contentRef,
  isTransparent: isTransparentEnabled = false,
}: ProviderProps): JSX.Element {
  const [isMenuOpened, setIsMenuOpened] = useState(DEFAULT_STATE.isMenuOpened);
  const [isTransparent, setIsTransparent] = useState(isTransparentEnabled);
  const [openedMenuGroupId, setOpenedMenuGroupId] = useState<string | null>(
    DEFAULT_STATE.openedMenuGroupId
  );

  const disableTransparency = useCallback((): void => {
    if (!isTransparentEnabled) {
      return;
    }

    setIsTransparent(false);
  }, [isTransparentEnabled]);

  const resetTransparency = useCallback((): void => {
    if (!isTransparentEnabled) {
      return;
    }

    setIsTransparent(isPageNotScrolled());
  }, [isTransparentEnabled]);

  const closeMenu = useCallback(() => {
    resetTransparency();
    setIsMenuOpened(false);
    setOpenedMenuGroupId(null);
  }, [resetTransparency]);

  useWindowWidthResize(closeMenu);
  useBlockScrolling(isMenuOpened);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    if (isMenuOpened) {
      contentRef.current.setAttribute("inert", "");

      return;
    }

    contentRef.current?.removeAttribute("inert");
  }, [contentRef, isMenuOpened]);

  useEffect(() => {
    if (!isMenuOpened) {
      return () => {};
    }

    function enterUpHandler(event: KeyboardEvent): void {
      if (event.code !== ESCAPE_KEY_CODE) {
        return;
      }

      closeMenu();
    }

    window.addEventListener("keyup", enterUpHandler);

    return () => {
      window.removeEventListener("keyup", enterUpHandler);
    };
  }, [isMenuOpened, closeMenu]);

  useEffect(() => {
    if (isMenuOpened || !openedMenuGroupId) {
      return () => {};
    }

    function enterUpHandler(event: KeyboardEvent): void {
      if (event.code !== ESCAPE_KEY_CODE) {
        return;
      }

      closeMenu();
    }

    window.addEventListener("keyup", enterUpHandler);

    return () => {
      window.removeEventListener("keyup", enterUpHandler);
    };
  }, [isMenuOpened, openedMenuGroupId, closeMenu]);

  useEffect(() => {
    if (!isTransparentEnabled) {
      return () => {};
    }

    setIsTransparent(isPageNotScrolled());

    function scrollHandler() {
      setIsTransparent(isPageNotScrolled());
    }

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [isTransparentEnabled]);

  const toggleTransparency = useCallback((): void => {
    if (!isTransparentEnabled) {
      return;
    }

    setIsTransparent((isTransparentValue) =>
      isTransparentValue ? !isTransparentValue : isPageNotScrolled()
    );
  }, [isTransparentEnabled]);

  const toggleMenu = useCallback((): void => {
    toggleTransparency();
    setIsMenuOpened((isOpened) => !isOpened);
    setOpenedMenuGroupId(null);
  }, [toggleTransparency]);

  const openMenuGroup = useCallback(
    (id: string, changeTransparency = false): void => {
      if (changeTransparency) {
        disableTransparency();
      }

      setOpenedMenuGroupId(id);
    },
    [disableTransparency]
  );

  const closeMenuGroup = useCallback(
    (changeTransparency = false): void => {
      if (changeTransparency) {
        resetTransparency();
      }

      setOpenedMenuGroupId(null);
    },
    [resetTransparency]
  );

  const state = useMemo<State>(
    () => ({
      isMenuOpened,
      isTransparent,
      openedMenuGroupId,
    }),
    [isMenuOpened, isTransparent, openedMenuGroupId]
  );

  const api = useMemo<Api>(
    () => ({
      toggleMenu,
      openMenuGroup,
      closeMenuGroup,
    }),
    [toggleMenu, openMenuGroup, closeMenuGroup]
  );

  return (
    <HeaderStateContext.Provider value={state}>
      <HeaderApiContext.Provider value={api}>
        {children}
      </HeaderApiContext.Provider>
    </HeaderStateContext.Provider>
  );
}
