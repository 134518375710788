import { SvgNameList } from "./icon.config";

type Props = {
  name: SvgNameList;
} & React.SVGProps<SVGSVGElement>;

const DEFAULT_SPRITE_PATH = "icons/sprite-v1.svg";

export function Icon({
  id,
  name,
  width = 48,
  height = 48,
  ...props
}: Props): JSX.Element {
  return (
    <svg
      id={id}
      width={width}
      height={height}
      viewBox="0 0 48 48"
      aria-hidden
      {...props}
    >
      <use href={`${DEFAULT_SPRITE_PATH}#${name}`} />
    </svg>
  );
}
