import classNames from "classnames";

import style from "./what-our-customers.module.scss";
import {
  ReviewCardProps,
  SecondarySection,
  Grid,
  ReviewCard,
} from "../../../../shared/ui";

export type Props = {
  title: string;
  reviewCardList: ReviewCardProps[];
};

export function WhatOurCustomers({
  reviewCardList,
  title,
}: Props): JSX.Element {
  return (
    <SecondarySection isVerticalPadding64>
      <Grid className={style.grid}>
        <div className={style.wrapper}>
          <h2 className={classNames(style.title, "h2")}>{title}</h2>
          <div className={style.gridCard}>
            {reviewCardList.map(({ link, rating, quote, titleImg }) => (
              <ReviewCard
                titleImg={titleImg}
                rating={rating}
                quote={quote}
                link={link}
                classname={style.reviewCard}
              />
            ))}
          </div>
        </div>
      </Grid>
    </SecondarySection>
  );
}
