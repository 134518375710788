import { ValuesOf } from "shared/config";

import style from "./loader.module.scss";

export type LoaderProps = {
  className?: string;
  color?: ValuesOfColorList;
  strokeWidth?: number | string;
};

export const colorList = {
  white: "white",
  blue: "blue",
  black: "black",
} as const;

export const colorToClassName: Record<ValuesOfColorList, string> = {
  [colorList.white]: style.white,
  [colorList.blue]: style.blue,
  [colorList.black]: style.black,
};

export type ValuesOfColorList = ValuesOf<typeof colorList>;
