const IMAGE_BASE_URL = "/images/pages/new/measure-and-control";

export const IMAGE_MEASURE_AND_CONTROL = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/santecknik.jpg`,
    retina: `${IMAGE_BASE_URL}/santecknik.jpg`,
    width: "343",
    height: "184",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/santecknik.jpg`,
    retina: `${IMAGE_BASE_URL}/santecknik.jpg`,
    width: "343",
    height: "184",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/santecknik.jpg`,
    retina: `${IMAGE_BASE_URL}/santecknik.jpg`,
    width: "802",
    height: "541",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/santecknik.jpg`,
    retina: `${IMAGE_BASE_URL}/santecknik.jpg`,
    width: "802",
    height: "541",
  },
  alt: "Наши профессионалы",
};
