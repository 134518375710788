import { ValuesOf } from "shared/config";

export const SlideId = {
  CLOUD_IVR: "cloudIVR",
  RECORDING: "recording",
  DIALER: "dialer",
  NUMBERS: "numbers",
  AGENT: "agent",
  REPORTING: "reporting",
  DASHBOARDS: "dashboards",
} as const;

export type ValuesOfSlideId = ValuesOf<typeof SlideId>;

export type Slide = {
  id: ValuesOfSlideId;
  title: string;
  topDescription: string;
  description: string;
  link: string;
};

export const SLIDES: Slide[] = [
  {
    id: SlideId.CLOUD_IVR,
    title: "Intelligent Skills-Based Routing",
    topDescription: "Efficiently route calls to the best agents",
    description:
      "Combine powerful queues using DTMF, agent skillsets, caller ID, and more with agent behaviors and history to master call routing.",
    link: "/cloud-ivr",
  },
  {
    id: SlideId.RECORDING,
    title: "Not All Call Recording Is Created Equal",
    topDescription: "A new standard for call recording",
    description:
      "Take advantage of customizable call events or stages, event-based navigation, an intuitive search engine, and more.",
    link: "/recording",
  },
  {
    id: SlideId.DIALER,
    title: "Outbound Dialer Built By Experts",
    topDescription: "A dialer that delivers",
    description:
      "Build campaigns and accelerate performance with predictive and progressive dialers.",
    link: "/dialer",
  },
  {
    id: SlideId.NUMBERS,
    title: "Find Local Numbers Globally",
    topDescription: "Numbers, numbers, numbers",
    description:
      "Port your existing numbers in or search our global inventory for find the perfect number for your business.",
    link: "/global-numbers",
  },
  {
    id: SlideId.AGENT,
    title: "Maximize Agent Output",
    topDescription: "Simple and intuitive agent interface",
    description:
      "Designed to simplify and automate, Voiso’s agent interface eliminates human error and maximizes agent performance.",
    link: "/agent-desktop",
  },
  {
    id: SlideId.REPORTING,
    title: "Build And Schedule Any Report You Need",
    topDescription: "Data at your fingertips",
    description:
      "Collect, compare, export and schedule historical reports and visualizations incorporating all the metrics within Voiso.",
    link: "/historical-reporting",
  },
  {
    id: SlideId.DASHBOARDS,
    title: "Dashboards To Drive Performance",
    topDescription: "Get more from your data",
    description:
      "Real-time metrics displayed across customizable views for supervisors, campaign managers and system admins.",
    link: "/dashboards",
  },
];
