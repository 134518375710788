const IMAGE_BASE_URL = '/images/pages/new/meet-our-team';

export const IMAGE_MEET_OUR_TEAM = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/meet-our-team-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/meet-our-team-sm@2x.webp`,
    width: '344',
    height: '178',
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/meet-our-team-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/meet-our-team-sm@2x.webp`,
    width: '344',
    height: '178',
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/meet-our-team-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/meet-our-team-lg@2x.webp`,
    width: '800',
    height: '343',
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/meet-our-team-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/meet-our-team-lg@2x.webp`,
    width: '800',
    height: '343',
  },
  alt: 'Our sales team in London at ICE 2023',
};
