import style from "./quote.module.scss";

export type Props = {
  content: string;
  author?: {
    name: string;
    position: string;
  };
};

export function Quote({ content, author }: Props): JSX.Element {
  return (
    <figure className={style.quote}>
      <blockquote className={style.quoteContent}>{content}</blockquote>
      {author && (
        <figcaption
          className={style.quoteAuthor}
        >{`${author.name}\n${author.position}`}</figcaption>
      )}
    </figure>
  );
}
