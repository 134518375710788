import classNames from "classnames";

import { Vacancy, VacancyProps } from "./components";
import style from "./join-voiso.module.scss";
import { SectionLayout } from "../../../../shared/ui";

export type Props = {
  title: any;
  vacancies: VacancyProps[];
};

export function JoinVoiso({ title, vacancies }: Props): JSX.Element {
  return (
    <SectionLayout>
      <h2 className={classNames("h2", style.title)}> {title} </h2>
      <div className={style.contentWrapper}>
        <div className={style.content}>
          {vacancies.map((vacancy) => (
            <Vacancy key={vacancy.title} {...vacancy} />
          ))}
        </div>
      </div>
    </SectionLayout>
  );
}
