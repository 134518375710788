import { SlideId, ValuesOfSlideId } from '../../cross-links.config';

import styles from './cross-links-slide-item.module.scss';

export const slideIdToModifier: Record<ValuesOfSlideId, string> = {
  [SlideId.CLOUD_IVR]: styles.sliderItemCloudIVR,
  [SlideId.RECORDING]: styles.sliderItemRecording,
  [SlideId.DIALER]: styles.sliderItemDialer,
  [SlideId.NUMBERS]: styles.sliderItemNumbers,
  [SlideId.AGENT]: styles.sliderItemAgent,
  [SlideId.REPORTING]: styles.sliderItemReporting,
  [SlideId.DASHBOARDS]: styles.sliderItemDashboards,
};
