import classNames from "classnames";

import style from "./main.module.scss";
import { MegaButtonImage, Grid } from "../../../../shared/ui";

type FeatureLink = {
  title: string;
  subtitle: string;
  href: string;
  img: MegaButtonImage;
};

export type Props = {
  title: {
    gradient: string;
    base: string;
  };
  subtitle: string;
  secondImage: any;
  features: {
    title: string;
    list: FeatureLink[];
  };
};

export function Main({ subtitle, title }: Props): JSX.Element {
  return (
    <section className={style.main}>
      <Grid className={style.grid}>
        <div className={style.info}>
          <h1 className={classNames("h1", style.gradient)}>
            {title.gradient}
            <span className={style.baseTitle}>{title.base}</span>
          </h1>
          <h2 className={style.subtitle}>{subtitle}</h2>
        </div>
      </Grid>
    </section>
  );
}
