import { ValuesOf } from "../../config";

export const SvgName = {
  REALTIME_DASHBOARD: "realTimeDashboard",
  EVENT_BASED_NAVIGATION: "eventBasedNavigation",
  SOFT_PHONE: "softphone",
  HISTORICAL_REPORTS: "historicalReports",
  DID_NUMBERS: "didNumbers",
  SHARP_LOCK: "sharpLock",
  REVERSE_CALL: "reverseCall",
  ADVANCED_DIALERS: "advancedDialers",
  AI_BASED_AMD: "aiBasedAmd",
  ADVANCED_CALL_REC: "advancedCallRec",
  WHISPER: "whisper",
  AGENT_CONTROL: "agentControl",
  MULTI_MONITORING: "multiMonitoring",
  MULTI_MONITORING_PERSON: "multiMonitoringPerson",
  QUALITY_STAR: "qualityStar",
  QUALITY_ROCKET: "qualityRocket",
  INTEGRATION: "integration",
  INFRASTRUCTURE: "infrastructure",
  SUPPORT: "support",
  INCREASE: "increase",
  BPO: "bpo",
  SHIELD: "shield",
  AVAILABLE: "available",
  NO_ROBOT: "noRobot",
  SMS: "sms",
  MAIL_WAVE: "mailWave",
  MAGNIFIER_STAR: "magnifierStar",
  MAGNIFIER_NO_CALL: "magnifierNoCall",
  EXPORT: "export",
  INFO: "info",
  FINANCIAL_SERVICES: "financialServices",
  BETTING: "betting",
  LICENSES: "licenses",
  NUMBER: "number",
  OMNI: "omni",
  IVR: "ivr",
  BLOG: "blog",
  REMOTE_HYBRID_TEAMS: "remoteHybridTeams",
  ONBOARDING: "onboarding",
  DEVICES: "devices",
  EVENTS: "events",
  CAREERS: "careers",
  TRAVEL: "travel",
  JOURNEY: "journey",
  LIKE: "like",
  DISLIKE: "dislike",
  DOCUMENTATION: "documentation",
  WARNING: "warning",
  IDEA: "idea",
  SCALE: "scale",
  CARE: "care",
  LOCATION: "location",
  FOOD: "food",
  INSURANCE: "insurance",
  LEARNING: "learning",
  BONUS: "bonus",
  SALARY: "salary",
  TIME: "time",
} as const;

export type SvgNameList = ValuesOf<typeof SvgName>;
