import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';

import { Icon, SvgName } from '../../ui/icon';
import { usePopup } from '../use-popup';

import styles from './popup-error.module.scss';

type Props = {
  isVisiblePopup: boolean;
  setIsVisiblePopup: Dispatch<SetStateAction<boolean>>;
  textError: string;
  classname?: string;
};

export function PopupError({ isVisiblePopup, setIsVisiblePopup, textError, classname = '' }: Props) {
  usePopup({
    isBlocking: isVisiblePopup,
    setIsBlocking: setIsVisiblePopup,
    classnamePopupContent: styles.popupContent,
  });

  return (
    <div className={classNames(styles.popupContainer, { [styles.isVisible]: isVisiblePopup }, classname)}>
      <div className={classNames('body-text', styles.popupContent)}>
        <Icon name={SvgName.WARNING} className={styles.svgIcon} width={24} height={24} />
        <span className={styles.popupLabel}> {textError} </span>
      </div>
    </div>
  );
}
