import { ImageSize } from "../../../shared/ui";
import {
  ValuesOfColorList,
  colorList,
} from "../components/get-valuable-insights/components";

const IMAGE_BASE_URL = "/images/pages/new/get-valuable-insights";
const PAGE_NAME = "get-valuable-insights";

export const IMAGE_GET_VALUABLE = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${PAGE_NAME}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${PAGE_NAME}-sm@2x.webp`,
    width: "277",
    height: "123",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${PAGE_NAME}-md@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${PAGE_NAME}-md@2x.webp`,
    width: "344",
    height: "178",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${PAGE_NAME}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${PAGE_NAME}-lg@2x.webp`,
    width: "750",
    height: "332",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${PAGE_NAME}-xl@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${PAGE_NAME}-xl@2x.webp`,
    width: "800",
    height: "343",
  },
  alt: "Proprietary speech analytics",
};

export const karmaImageData = {
  size: ImageSize.L,
  src: "/images/pages/customer-stories/karma/karma-desktop@2x.webp",
  alt: "Karma hotel",
};

type Chip = {
  text: string;
  color: ValuesOfColorList;
};

type ChipListType = Record<string, Chip[]>;

export const chipList: ChipListType = {
  step0: [
    {
      text: "order",
      color: colorList.PURPLE,
    },
  ],
  step1: [
    {
      text: "not getting",
      color: colorList.ORANGE,
    },
  ],
  step2: [
    {
      text: "order",
      color: colorList.PURPLE,
    },
    {
      text: "inconvenience",
      color: colorList.ORANGE,
    },
  ],
  step3: [
    {
      text: "order",
      color: colorList.PURPLE,
    },
    {
      text: "Thank you",
      color: colorList.GREEN,
    },
  ],
  step4: [
    {
      text: "order",
      color: colorList.PURPLE,
    },
    {
      text: "shipping",
      color: colorList.PURPLE,
    },
  ],
  step5: [
    {
      text: "apologize",
      color: colorList.GREEN,
    },
  ],
  step6: [
    {
      text: "help you",
      color: colorList.GREEN,
    },
  ],
};
