import classNames from "classnames";
import {
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
} from "react";

import styles from "./textarea.module.scss";

type HTMLInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

interface Props extends HTMLInputProps {
  label: string;
}

function TextaReaComponent(
  { label, className, ...inputProps }: Props,
  forwardedRef?: ForwardedRef<HTMLTextAreaElement>
) {
  return (
    <div className={className}>
      <label className={classNames(styles.label)}>
        {label}
        <textarea
          className={classNames(styles.input)}
          ref={forwardedRef}
          {...inputProps}
        />
      </label>
    </div>
  );
}

export const Textarea = forwardRef(TextaReaComponent);
