import { Image } from "../../../shared/ui";

type BlockDataItem = {
  img: Image;
  subTitle: string;
  description: string;
};

const IMAGE_BASE_URL = "/images/pages/new/reliable-infrastructure";

const defaultImgName = "default";
const blueWorldImgName = "blue";
const pointsImgName = "points";
const shieldImgName = "shield";

export const defaultImgData = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${defaultImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${defaultImgName}-sm@2x.webp`,
    width: "344",
    height: "175",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${defaultImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${defaultImgName}-sm@2x.webp`,
    width: "344",
    height: "178",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${defaultImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${defaultImgName}-lg@2x.webp`,
    width: "662",
    height: "337",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${defaultImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${defaultImgName}-lg@2x.webp`,
    width: "800",
    height: "343",
  },
  alt: "Map world",
};

const blueWorldImgData = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${blueWorldImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${blueWorldImgName}-sm@2x.webp`,
    width: "344",
    height: "175",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${blueWorldImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${blueWorldImgName}-sm@2x.webp`,
    width: "344",
    height: "178",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${blueWorldImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${blueWorldImgName}-lg@2x.webp`,
    width: "662",
    height: "337",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${blueWorldImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${blueWorldImgName}-lg@2x.webp`,
    width: "800",
    height: "343",
  },
  alt: "Regions of the world",
};

const pointsImgData = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${pointsImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${pointsImgName}-sm@2x.webp`,
    width: "344",
    height: "175",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${pointsImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${pointsImgName}-sm@2x.webp`,
    width: "344",
    height: "178",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${pointsImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${pointsImgName}-lg@2x.webp`,
    width: "662",
    height: "337",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${pointsImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${pointsImgName}-lg@2x.webp`,
    width: "800",
    height: "343",
  },
  alt: "Points on the world map",
};

const shieldImgData = {
  mobile: {
    normal: `${IMAGE_BASE_URL}/${shieldImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${shieldImgName}-sm@2x.webp`,
    width: "344",
    height: "175",
  },
  tablet: {
    normal: `${IMAGE_BASE_URL}/${shieldImgName}-sm@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${shieldImgName}-sm@2x.webp`,
    width: "344",
    height: "178",
  },
  desktop: {
    normal: `${IMAGE_BASE_URL}/${shieldImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${shieldImgName}-lg@2x.webp`,
    width: "662",
    height: "337",
  },
  desktopXl: {
    normal: `${IMAGE_BASE_URL}/${shieldImgName}-lg@1x.webp`,
    retina: `${IMAGE_BASE_URL}/${shieldImgName}-lg@2x.webp`,
    width: "800",
    height: "343",
  },
  alt: "Security",
};

export const blockData: BlockDataItem[] = [
  {
    img: blueWorldImgData,
    subTitle: "Global coverage and numbers",
    description:
      "Coverage in 140 + countries, including UAE, Singapore, Germany, Switzerland, and the UK",
  },
  {
    img: pointsImgData,
    subTitle: "Premium voice quality",
    description: "6 data centers and 3 NOCs, and intelligent AI routing",
  },
  {
    img: shieldImgData,
    subTitle: "High security standards",
    description:
      "GPDR and PCI DSS compliant, end-to-end encryption and  secure call recording",
  },
];
