import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/globals.scss";
import { Careers } from "./pages/careers";
import { Request } from "./pages/request";
import { MainPage } from "./pages/index";
import { Prices } from "./pages/prices";
import { Documents } from "./pages/documents";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/about" element={<MainPage />} />
      <Route path="/prices" element={<Prices />} />
      <Route path="/request" element={<Request />} />
      <Route path="/contacts" element={<Careers />} />
      <Route path="/documents" element={<Documents />} />
    </Routes>
  </BrowserRouter>
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
