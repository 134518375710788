import classNames from 'classnames';
import { DetailedHTMLProps, ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import styles from './input.module.scss';

type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface Props extends HTMLInputProps {
  label: string;
  errorMessage?: string;
  isInputPhone?: boolean;
  isVisiblePlus?: boolean;
}

function InputComponent(
  { label, className, isInputPhone = false, isVisiblePlus = false, errorMessage = '', ...inputProps }: Props,
  forwardedRef?: ForwardedRef<HTMLInputElement>,
) {
  const hasError = !!errorMessage;

  return (
    <div className={className}>
      <label
        className={classNames(styles.label, {
          [styles.labelPhone]: isInputPhone && isVisiblePlus,
          [styles.labelPhoneError]: isInputPhone && hasError,
        })}
      >
        {label}
        <input
          className={classNames(styles.input, {
            [styles.inputInvalid]: hasError,
          })}
          ref={forwardedRef}
          {...inputProps}
        />
      </label>

      <span
        className={classNames(styles.error, {
          [styles.errorVisible]: hasError,
        })}
      >
        {errorMessage}
      </span>
    </div>
  );
}

export const Input = forwardRef(InputComponent);
