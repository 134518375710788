import classNames from 'classnames';
import { createElement, forwardRef, ReactNode, Ref } from 'react';

import style from './grid.module.scss';

type GridRef = Ref<HTMLDivElement | HTMLUListElement>;

type Props = {
  children: ReactNode;
  className?: string;
  as?: 'div' | 'section' | 'ul';
} & (JSX.IntrinsicElements['ul'] | JSX.IntrinsicElements['div'] | JSX.IntrinsicElements['section']);

export const Grid = forwardRef(
  ({ children, className = undefined, as = 'div', ...props }: Props, ref: GridRef): JSX.Element =>
    createElement(as, { className: classNames(style.grid, className), ref, ...props }, children),
);
Grid.displayName = 'Grid';
