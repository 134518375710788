import classNames from 'classnames';

import { Icon } from '../../../icon';
import { TextLink } from '../../buttons/text-link';
import { LazyNextContentImage } from '../../imgs/lazy-next-content-image';

import {
  Props,
  ColorTextProps,
  ImagePropsUC,
  SVGPropsUC,
  isColorText,
  isImage,
  PropsWrapper,
} from './univarsal-card.config';
import style from './universal-card.module.scss';

function Wrapper({
  titleText,
  link = undefined,
  classname = undefined,
  description = '',
  children,
}: PropsWrapper): JSX.Element {
  return (
    <article className={classNames(style.card, classname)}>
      <div className={style.content}>
        {children}

        <h3 className={classNames('h3', 'gradient-text', style.title)}>
          {typeof titleText === 'string' ? (
            <span className={style.baseText}> {titleText} </span>
          ) : (
            titleText.map(({ value, isGradient }) =>
              !isGradient ? (
                <span key={value} className={style.baseText}>
                  {' '}
                  {value}{' '}
                </span>
              ) : (
                value
              ),
            )
          )}
        </h3>

        {description && <p className={classNames(style.description, 'body-text')}>{description}</p>}
      </div>
      {link && <TextLink href={link.href} text={link.text} />}
    </article>
  );
}

export function Card({
  titleText,
  link = undefined,
  classname = undefined,
  description = '',
  ...props
}: Props<ColorTextProps> | Props<ImagePropsUC> | Props<SVGPropsUC>): JSX.Element {
  if (isColorText(props)) {
    return (
      <Wrapper titleText={titleText} link={link} classname={classname} description={description}>
        <p className={classNames(style.coloredTextTest, style[props.coloredText.color])}> {props.coloredText.value} </p>
      </Wrapper>
    );
  }

  if (isImage(props)) {
    return (
      <Wrapper titleText={titleText} link={link} classname={classname} description={description}>
        <LazyNextContentImage
          src={props.img.src}
          alt={props.img.alt}
          title={props.img.title}
          width={props.img.width}
          height={props.img.height}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper titleText={titleText} link={link} classname={classname} description={description}>
      <div className={style.svgWrapper}>
        <Icon name={props.svg.svgName} className={props.svg.className} aria-hidden />
      </div>
    </Wrapper>
  );
}
