import classNames from "classnames";

import { QuoteProps, Rating, Quote } from "./components";
import style from "./review-card.module.scss";

import { ReactComponent as ArrowRight } from "icons/arrowRight.svg";

export type Props = {
  titleImg?: {
    src: string;
    width: number;
    height: number;
    alt: string;
  };
  rating: number;
  quote: QuoteProps;
  link?: {
    href: string;
    title: string;
  };
  classname?: string;
};

export function ReviewCard({
  rating,
  quote,
  link,
  titleImg,
  classname = "",
}: Props): JSX.Element {
  return (
    <article className={classNames(style.reviewCard, classname)}>
      {titleImg && (
        <h3 className="visually-hidden">Review from {titleImg.alt}</h3>
      )}
      <div className={style.review}>
        {titleImg && (
          <img
            src={titleImg.src}
            alt={titleImg.alt}
            width={titleImg.width}
            height={titleImg.height}
          />
        )}
        <Rating rating={rating} />
        <Quote content={quote.content} author={quote.author} />
      </div>
      {link && (
        <a
          href={link.href}
          className={style.link}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          {link.title}
          <ArrowRight aria-hidden />
        </a>
      )}
    </article>
  );
}
