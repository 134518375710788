/* eslint-disable react/jsx-no-bind */ // чтобы прокинуть функцию как props onClick в кнопку

import classNames from "classnames";
import { Dispatch, SetStateAction } from "react";

import {
  UiButton,
  Grid,
  ButtonSize,
  ButtonTypeList,
  ButtonColorList,
} from "shared/ui";

import { usePopup } from "../use-popup";

import styles from "./popup-thank-you.module.scss";

type Props = {
  isVisiblePopup: boolean;
  setIsVisiblePopup: Dispatch<SetStateAction<boolean>>;
  classname?: string;
};

export function PopupThankYou({
  isVisiblePopup,
  setIsVisiblePopup,
  classname = "",
}: Props) {
  const timeoutId = usePopup({
    isBlocking: isVisiblePopup,
    setIsBlocking: setIsVisiblePopup,
    classnamePopupContent: styles.popupContent,
  });

  const onDeleteByBtnFromModal = () => {
    if (timeoutId?.current) clearTimeout(timeoutId.current);
    setIsVisiblePopup(false);
  };

  return (
    <Grid
      className={classNames(
        styles.popupContainer,
        { [styles.isVisible]: isVisiblePopup },
        classname
      )}
    >
      <div className={styles.popupContent}>
        <h2 className={classNames("h2", styles.title)}> Спасибо </h2>
        <p className={classNames("body-text", styles.description)}>
          Мы обработаем вашу заявку как можно скорее
        </p>

        <UiButton
          classname={styles.btn}
          size={ButtonSize.L}
          variantType={ButtonTypeList.FILL}
          color={ButtonColorList.PRIMARY}
          text="На главную"
          href="/"
          onClick={onDeleteByBtnFromModal}
        />
      </div>
    </Grid>
  );
}
