import classNames from 'classnames';
import { useState } from 'react';

import style from './content-image.module.scss';

export type Image = {
  mobile: DeviceType;
  tablet: DeviceType;
  desktop: DeviceType;
  desktopXl: DeviceType;
  alt: string;
};

type DeviceType = {
  retina: string;
  normal: string;
  width?: string;
  height?: string;
};

type Props = {
  image: Image;
  className?: string;
  hasLoadingBackground?: boolean;
};

export function ContentImage({ image, className = '', hasLoadingBackground = true }: Props): JSX.Element {
  const [isLoading, setLoading] = useState(hasLoadingBackground);

  return (
    <picture className={classNames(className, { [style.isLoading]: isLoading })}>
      <source
        media="(min-width: 2400px)"
        srcSet={`${image.desktopXl.normal}, ${image.desktopXl.retina} 2x`}
        width={image.desktopXl.width}
        height={image.desktopXl.height}
      />
      <source
        media="(min-width: 1280px)"
        srcSet={`${image.desktop.normal}, ${image.desktop.retina} 2x`}
        width={image.desktop.width}
        height={image.desktop.height}
      />
      <source
        media="(min-width: 768px)"
        srcSet={`${image.tablet.normal}, ${image.tablet.retina} 2x`}
        width={image.tablet.width}
        height={image.tablet.height}
      />
      <img
        src={image.mobile.normal}
        srcSet={`${image.mobile.retina} 2x`}
        alt={image.alt}
        className={style.image}
        loading="lazy"
        decoding="async"
        width={image.mobile.width}
        height={image.mobile.height}
        onLoad={() => setLoading(false)}
      />
    </picture>
  );
}
