import classNames from "classnames";

import { ReactComponent as ArrowRight } from "icons/arrowRight.svg";

import { Loader } from "../../../loader";

import {
  ButtonBodyProps,
  ButtonProps,
  LinkProps,
  Props,
} from "./button.config";
import { isLink } from "./button.lib";
import style from "./button.module.scss";

function ButtonBody({
  text,
  isLoading = false,
  withArrow,
  leftArrow,
  children,
}: ButtonBodyProps) {
  return (
    <>
      {isLoading && <Loader className={style.loader} strokeWidth={2} />}
      {text && (
        <span className={classNames({ [style.loading]: isLoading })}>
          {text}
        </span>
      )}
      {withArrow && (
        <ArrowRight
          aria-hidden
          className={classNames(style.svg, {
            [style.loading]: isLoading,
            [style.leftArrow]: leftArrow,
          })}
        />
      )}
      {children}
    </>
  );
}

export function Button({
  text = "",
  classname = "",
  withArrow = false,
  leftArrow = false,
  variantType,
  size,
  color,
  children,
  ...props
}: Props<ButtonProps> | Props<LinkProps>) {
  const buttonClassName = classNames(
    classname,
    style.button,
    style[size],
    style[variantType],
    style[color]
  );

  if (isLink(props)) {
    const { href, onClick, scroll, ...rest } = props;

    const isExternalLink =
      typeof href === "string" &&
      !(href?.startsWith("/") || href?.startsWith("#"));

    return (
      <a
        href={href}
        className={buttonClassName}
        target={isExternalLink ? "_blank" : "_self"}
        rel={isExternalLink ? "noopener noreferrer nofollow" : undefined}
        onClick={onClick}
        {...rest}
      >
        <ButtonBody text={text} withArrow={withArrow} leftArrow={leftArrow}>
          {children}
        </ButtonBody>
      </a>
    );
  }

  const { onClick, type, disabled, isLoading, ...rest } = props;

  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      className={buttonClassName}
      data-loading={isLoading}
      onClick={onClick}
      disabled={disabled || isLoading}
      {...rest}
    >
      <ButtonBody
        text={text}
        isLoading={isLoading}
        withArrow={withArrow}
        leftArrow={leftArrow}
      >
        {children}
      </ButtonBody>
    </button>
  );
}
