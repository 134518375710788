import classNames from "classnames";

import { useContext } from "react";

import { ReactComponent as BurgerMenuIcon } from "icons/burgerMenu.svg";
import { ReactComponent as CloseIcon } from "icons/close.svg";

import {
  ButtonColorList,
  ButtonSize,
  ButtonTypeList,
  UiButton,
} from "shared/ui";

import {
  HeaderApiContext,
  HeaderStateContext,
} from "../../contexts/header-context";

import { MenuWithAccordions } from "./components/menu-with-accordions";
import { MenuWithDropdowns } from "./components/menu-with-dropdowns";
import { NAVIGATION_ID } from "./header.constants";
import style from "./header.module.scss";
import { Props } from "./header.types";

export function Header({
  className = "",
  withMenu = true,
}: Props): JSX.Element {
  const { isMenuOpened, isTransparent, openedMenuGroupId } =
    useContext(HeaderStateContext);
  const { toggleMenu } = useContext(HeaderApiContext);

  const burgerMenuLabel = isMenuOpened ? "Close menu" : "Open menu";

  return (
    <header className={classNames(className, style.header)}>
      {isMenuOpened && (
        <button
          className={style.overlay}
          type="button"
          aria-hidden
          onClick={toggleMenu}
          tabIndex={-1}
        />
      )}

      {openedMenuGroupId && !isMenuOpened && (
        <div className={style.overlay} aria-hidden />
      )}

      <nav id={NAVIGATION_ID} className={style.navigation}>
        <div
          className={classNames(style.controlsWrapper, {
            [style.transparentControlsWrapper]: isTransparent,
            [style.withMenu]: withMenu,
          })}
        >
          {withMenu && <MenuWithDropdowns className={style.dropdownMenu} />}
          {withMenu && (
            <div className={style.buttons}>
              <UiButton
                type="button"
                color={
                  isTransparent ? ButtonColorList.WHITE : ButtonColorList.BLACK
                }
                variantType={ButtonTypeList.STROKE}
                size={ButtonSize.S}
                onClick={toggleMenu}
                aria-expanded={isMenuOpened}
                aria-controls={NAVIGATION_ID}
                aria-label={burgerMenuLabel}
                classname={style.withBurger}
              >
                {isMenuOpened ? (
                  <CloseIcon aria-hidden />
                ) : (
                  <BurgerMenuIcon aria-hidden />
                )}
              </UiButton>
            </div>
          )}
        </div>

        {withMenu && (
          <MenuWithAccordions
            className={classNames(style.accordionMenu, {
              [style.closedAccordionMenu]: !isMenuOpened,
            })}
          />
        )}
      </nav>
    </header>
  );
}
