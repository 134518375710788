import classNames from "classnames";

import style from "./vacancy.module.scss";
import {
  SvgNameList,
  Icon,
  UiButton,
  ButtonSize,
  ButtonTypeList,
  ButtonColorList,
} from "../../../../../shared/ui";

type Svg = {
  svgName: SvgNameList;
  width: number;
  height: number;
};

export type SvgWithText = {
  text: string;
  svg: Svg;
};

export type Props = {
  title: any;
  conditionsBlock: SvgWithText[];
};

export function Vacancy({ title, conditionsBlock }: Props): JSX.Element {
  return (
    <div className={style.position}>
      <div className={style.wrapperPosition}>
        <h2 className={classNames("h2", style.positionName)}> {title}</h2>

        <div className={style.conditionsJobSection}>
          {conditionsBlock.map((conditionItem) => (
            <div className={style.conditionsJobItem} key={conditionItem.text}>
              <Icon
                name={conditionItem.svg.svgName}
                width={conditionItem.svg.width}
                height={conditionItem.svg.height}
                className={style.svgIconCondition}
              />
              <span className="caps-text"> {conditionItem.text} </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
