import { ReactNode } from "react";

import { Button } from "../button";
import { Header } from "../headers";

import style from "./first-section.module.scss";

type Props = {
  subTitle: JSX.Element | string;
  title: JSX.Element | string;
  hideButton?: boolean;
  descriptionText?: JSX.Element | string;
  children?: ReactNode;
  supertitle?: string;
};

export function FirstSection({
  children = null,
  subTitle,
  supertitle,
  title,
  hideButton = false,
  descriptionText = "",
}: Props): JSX.Element {
  return (
    <section className={style.sectionWrapper}>
      <div className={style.section}>
        {supertitle && (
          <Header.H1 className={style.title} color="black" text={supertitle} />
        )}
        {title && (
          <Header.H2 className={style.title} color="black" text={title} />
        )}

        <p className={style.subTitle}>{subTitle}</p>

        {descriptionText && (
          <p className={style.description}>{descriptionText}</p>
        )}

        {!hideButton && (
          <div className={style.button}>
            <Button.Secondary
              text="Request a demo"
              href="/request-a-demo"
              withArrow
            />{" "}
          </div>
        )}
      </div>
      {children && <div className={style.content}>{children}</div>}
    </section>
  );
}
