import classNames from "classnames";

import { Icon } from "shared/ui/icon";

import styles from "./links-group.module.scss";
import { Props } from "./links-group.types";

export function LinksGroup({
  groups,
  isGroupTitleHidden,
  isOneColumn,
  className = "",
}: Props): JSX.Element {
  return (
    <dl className={classNames(className, styles.groups, "second-text")}>
      {groups.map(({ title: groupTitle, links }) => (
        <div className={styles.groupItem} key={groupTitle}>
          <dt
            className={classNames(styles.groupTitle, {
              "visually-hidden": isGroupTitleHidden,
            })}
          >
            {groupTitle}
          </dt>

          <dd>
            <ul
              className={classNames(styles.links, {
                [styles.oneColumnLinks]: isOneColumn,
              })}
            >
              {links.map(
                ({
                  title: linkTitle,
                  href,
                  description,
                  isExternalLink,
                  hasNewLabel,
                  icon,
                }) => {
                  const linkTarget = isExternalLink ? "_blank" : undefined;
                  const linkRel = isExternalLink
                    ? "noreferrer noopener nofollow"
                    : undefined;

                  return (
                    <li key={linkTitle}>
                      <a
                        href={href}
                        className={classNames(styles.linkItem, {
                          [styles.withIcon]: !!icon,
                        })}
                        target={linkTarget}
                        rel={linkRel}
                      >
                        {icon && (
                          <Icon name={icon} className={styles.headerIcon} />
                        )}
                        {linkTitle}
                        {hasNewLabel && (
                          <span className={styles.label}>New</span>
                        )}
                        {description && (
                          <p className={styles.linkDescription}>
                            {description}
                          </p>
                        )}
                      </a>
                    </li>
                  );
                }
              )}
            </ul>
          </dd>
        </div>
      ))}
    </dl>
  );
}
