import classNames from "classnames";

import style from "./text-link.module.scss";

import { ReactComponent as ArrowRight } from "icons/arrowRight.svg";

export type Props = {
  text: string;
  href: string;
  classname?: string;
  withoutArrow?: boolean;
};

export function TextLink({
  text,
  href,
  classname = undefined,
  withoutArrow = false,
}: Props): JSX.Element {
  return (
    <a href={href} className={classNames(style.linkWrapper, classname)}>
      <span className={style.link}>
        {text}
        {!withoutArrow && <ArrowRight aria-hidden />}
      </span>
    </a>
  );
}
