import {
  KeepAllCommunicationProps,
  Main,
  MainProps,
  MeasureAndControl,
  MeasureAndControlProps,
  ReliableInfrastructureProps,
  WhatOurCustomers,
  WhatOurCustomersProps,
} from "./components";
import { SECOND_IMAGE_MAIN, IMAGE_MEASURE_AND_CONTROL } from "./config";
import { NewFormProps } from "../../new-form";
import { ImageSize, SvgName, Layout } from "../../shared/ui";

export type Props = {
  measureAndControl: MeasureAndControlProps;
  main: MainProps;
  keepAllCommunication: KeepAllCommunicationProps;
  whatOurCustomers: WhatOurCustomersProps;
  reliableInfrastructure: ReliableInfrastructureProps;
  form: NewFormProps;
};

const props = {
  main: {
    title: {
      gradient: "ООО «Мастер Тек» ",
      base: "помогает вам с 2009 года",
    },
    subtitle:
      "ООО «Мастер Тек» основана в 2009 году. Организация оказывает услуги по содержанию и эксплуатации зданий и сооружений. Успешно применяем свой опыт, помогая вам безопасно, удобно и комфортно жить",
    secondImage: SECOND_IMAGE_MAIN,
    features: {
      title: "Explore native integrations with major CRM systems",
      list: [
        {
          title: "Hubspot",
          subtitle: "CRM",
          href: "#",
          img: {
            src: "/images/pages/main/new-main-icons/hubspot.webp",
            alt: "Hubspot",
            size: ImageSize.S,
          },
        },
        {
          title: "Salesforce",
          subtitle: "CRM",
          href: "https://appexchange.salesforce.com/listingDetail?listingId=a0N4V00000GwHtnUAF&tab=e",
          img: {
            src: "/images/pages/main/new-main-icons/salesforce.webp",
            alt: "Salesforce",
            size: ImageSize.S,
          },
        },
        {
          title: "Zoho",
          subtitle: "CRM",
          href: "https://marketplace.zoho.com/app/crm/voiso-contact-center-for-zoho",
          img: {
            src: "/icons/zoho.svg",
            alt: "Zoho",
            size: ImageSize.S,
          },
        },
      ],
    },
  },

  measureAndControl: {
    subTitle:
      "Основной состав коллектива ООО «Мастер Тек» имеет большой опыт работы в сфере обслуживания зданий и коммунального хозяйства.",
    description: "",
    featuresItems: [
      {
        svgName: SvgName.INFO,
        featureTitle: "Замена внутренних инженерных коммуникаций",
        description: "",
      },
      {
        svgName: SvgName.INFO,
        featureTitle: "Ремонт кровли",
        description: "",
      },
      {
        svgName: SvgName.INFO,
        featureTitle: "Ремонт и уборка подъездов",
        description: "",
      },
      {
        svgName: SvgName.INFO,
        featureTitle: "Множество других услуг",
        description: "",
      },
    ],
    img: IMAGE_MEASURE_AND_CONTROL,

    clientsReviewTitle: "Why clients love Voiso",
    quotes: [
      {
        quote:
          '"Handling a team of agents with different attitudes and background is not easy. But with Real-Time Dashboard, monitoring the team\'s performance and managing capacity became much easier for me."',
        author: {
          name: "Mae Igot",
          position: "BlueCalls, supervisor",
        },
        icon: {
          src: "/images/pages/new/measure-and-control/bluecalls.webp",
          alt: "BlueCalls",
        },
      },
      {
        quote:
          "“Voiso is easy to navigate: the interface is user-friendly and can be modified to your needs. Our team especially values the integration with Zoho CRM, and I'm also extremely impressed by Real-Time Dashboard, which lets me see all information about the team's workflow. Plus, their support team is always fast to respond, active and approachable!”",
        author: {
          name: "Arsenio N. Trigo",
          position: "MBM, MPSM GENERAL MANAGER TMG",
        },
        icon: {
          src: "/images/pages/new/measure-and-control/tmg.webp",
          alt: "TMG",
        },
      },
    ],
  },

  whatOurCustomers: {
    title: "Больше о нас",
    reviewCardList: [
      {
        rating: 5,
        quote: {
          content:
            "Выполняем ремонтно-строительные работы по договорам с частными лицами: ремонт квартир; ремонт, замена сантехнического оборудования и внутриквартирных инженерных коммуникаций; ремонт и замена внутриквартирного электрооборудования.  ",
        },
      },
      {
        rating: 5,
        quote: {
          content:
            "Имеем опыт исполнения государственных контрактов по комплексному обслуживанию зданий и сооружений.",
        },
      },
      {
        rating: 5,
        quote: {
          content:
            "Организация имеет сертификат соответствия по добровольной сертификации жилищно-коммунальных услуг, выданный системой добровольной сертификации «МОСЖКХ» (зарегистрирована в едином реестре зарегистрированных систем добровольной сертификации регистрационный номер РОСС RU.И1181.04ЖНН0).",
        },
      },
    ],
  },
};

export function MainPage(): JSX.Element {
  const { main, measureAndControl, whatOurCustomers } = props;

  return (
    <Layout>
      <Main
        title={main.title}
        subtitle={main.subtitle}
        secondImage={main.secondImage}
        features={main.features}
      />
      <MeasureAndControl
        subTitle={measureAndControl.subTitle}
        description={measureAndControl.description}
        featuresItems={measureAndControl.featuresItems}
        img={measureAndControl.img}
        clientsReviewTitle={measureAndControl.clientsReviewTitle}
        quotes={measureAndControl.quotes}
      />
      <WhatOurCustomers
        title={whatOurCustomers.title}
        reviewCardList={whatOurCustomers.reviewCardList}
      />
    </Layout>
  );
}
